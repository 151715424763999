import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import BlogPostContainer from "../BlogPostContainer";
import BlogPostDateAndReadtime from "../BlogPostDateAndReadtime";
import BlogPostSubheader from "../BlogPostSubheader";

const MoreSubscriptionsNewsletter = () => {
   let nyhedsbrev = require("../../../_images/flere-tilmeldinger-til-nyhedsbrev/nyhedsbrev.jpg");

   return (
      <BlogPostContainer>
         <Helmet>
            <title>Få flere tilmeldinger til dit nyhedsbrev. Nu.</title>
         </Helmet>
         <h1 className="title">Få flere tilmeldinger til dit nyhedsbrev. Nu.</h1>
         <BlogPostDateAndReadtime date="17. september 2019" readtime="Læsetid: 4-5 minutter" />
         <figure className="image">
            <img src={nyhedsbrev} alt="nyhedsbrev.jpg" />
         </figure>
         <p>
            Når du nu bruger tid på at skrive et nyhedsbrev, er det rart med så mange modtagere som muligt. Og hvordan får du så det? Der er mange veje til at udvide listen – her får du mine 10 favoritter. Og til slut får du et lille ekstra tip, som øger værdien af alle de andre.
         </p>
         <BlogPostSubheader text="1. Indsaml mailadresser offline" />
         <p>
            Har du en fysisk butik, klinik eller lignende? Skal du på messe eller til netværksmøde? Alle de steder, hvor du møder dine kunder face-to-face, er en potentiel mulighed for
            at indsamle leads. Det eneste du behøver, er en flyer eller lignende, hvor man kan tilmelde sig.{" "}
         </p>
         <BlogPostSubheader text="2. Pop up eller slide-in" />
         <p>
            Medmindre du har været på en virkelig lang ekspedition tværs over indlandsisen, så kan du næppe ha’ undgået at bemærke de mange lead-genererende pop ups, som du finder
            rundt omkring på nettet. De er der selvfølgelig af en grund. De virker nemlig. Så hvis du ikke har en allerede, er det bare med at komme i gang. Der findes en del
            forskellige udbydere. Jeg har selv arbejdet med både bmetric, Sleeknote og SiteCampaign, som alle kan anbefales.{" "}
         </p>
         <BlogPostSubheader text="3. Simpel tilmeldingsformular" />
         <p>
            Og når du nu laver din pop up, så husk at gøre den så nem som mulig at udfylde. Der er ingen grund til at bede om flere informationer end højst nødvendigt. Jeg anbefaler to
            felter: Fornavn og mailadresse.{" "}
         </p>
         <BlogPostSubheader text="4. Gamification" />
         <p>
            Gamification handler om at involvere brugerne i interaktive, online spil. Der er mange interessante udbytter ved
            denne form for markedsføring, og en af dem er opsamling af nye leads til dit nyhedsbrev. Spil appellerer til de fleste målgrupper, og hvis du har lavet et underholdende et
            af slagsen, kan du komme rigtig bredt ud.
         </p>
         <BlogPostSubheader text="5. Brug dine eksisterende modtagere" />
         <p>
            Hvis en eksisterende modtager har læst dit nyhedsbrev fra start til slut, så er det formentlig fordi vedkommende fandt dit indhold interessant (ellers er det nok en ret
            usædvanlig type med virkelig meget tid). Og når nu folk synes, at dit indhold er interessant, så har de måske lyst til at dele det med andre. Derfor kan du med fordel
            slutte udvalgte nyhedsbreve af med en opfordring til at dele indholdet.{" "}
         </p>
         <BlogPostSubheader text="6. Social proof" />
         <p>
            Og nu vi taler om dine modtagere, så kan du også bruge dem på anden vis (forudsat at du har mere end et par stykker). Så snart din liste når en betydelig størrelse, kan du
            bruge social proof ved at skrive noget a la “Gør som 5.000 andre og bliv en del af klubben i dag”. Mennesker er flokdyr – vi kan godt lide at gøre det samme som andre, og
            derfor giver det ekstra troværdighed til din liste at nævne alle dem, som allerede har tilmeldt sig.{" "}
         </p>
         <BlogPostSubheader text="7. Udnyt dit website" />
         <p>
            Mange hjemmesider har et link til deres nyhedsbrev i footeren, men det giver desværre sjældent nye tilmeldinger. Sagen er nemlig den, at de færreste når hele vejen
            ned til bunden. Derfor kan du med fordel placere en tilmeldingsformular på mere attraktive steder.
         </p>
         <p>
            Du kan fx placere den i headeren og dermed sikre at den vises globalt,
            dvs. uanset hvilken side brugeren befinder sig på. Har du en blog, giver det også rigtig god mening at kombinere med sign up. Her kan du endda målrette dit budskab efter
            det indhold, som brugeren er ved at læse om. Som med alt andet giver det højere konvertering, når du rammer præcist ind i brugerens interessefelt, og det gør du her.{" "}
         </p>
         <BlogPostSubheader text="8. Teaser på sociale medier" />
         <p>
            Har du mange følgere på fx Facebook, kan du lave en teaser for dit kommende nyhedsbrev. Det kunne være noget a la: “Om 12 timer udsender jeg mit næste nyhedsbrev. Her kan
            du kan læse om 5 klassiske fejl til jobsamtalen, og hvordan du kan undgå dem.” Du skal have noget godt at lokke med – ellers virker det ikke. Eksemplet her spiller på
            frygten og er derfor et stærkt kort.
         </p>
         <p>
            OBS. Fordi du udelukkende taler til folk som allerede følger dig, risikerer du at få en del gengangere, dvs. folk der allerede er
            tilmeldt. Men den risiko er altid til stede, og eftersom dette tip ikke koster mere end fem minutters arbejdstid, er det stadig tiden værd.{" "}
         </p>
         <BlogPostSubheader text="9. Lead ads på Facebook" />
         <p>
            Hvis du vil have en stor liste, kommer du ikke uden om at bruge lidt penge på annoncering. Den gode nyhed er, at det ikke behøver at koste en bondegård. Hvis du får opsat
            nogle skarpe annoncer, kan du få gode kvalitetsleads helt ned til 2-3 kroner pr. styk.{" "}
         </p>
         <BlogPostSubheader text="10. Google Ads" />
         <p>
            Google Ads (og ikke AdWords som det ellers har heddet i mange år) er måske ikke det første, du tænker på ift. leads, men hvis du har noget godt content på dit website, så
            kan det give rigtig god mening. Det kræver bare at du har en landingsside med noget gated content, hvilket vil sige at du skal tilmelde dig nyhedsbrevet for at få fat i
            det. Det kunne være en e-bog, en samling af opskrifter eller hvad du nu har af godt indhold.{" "}
         </p>
         <BlogPostSubheader text="Bonus-tip" />
         <p>
            Som konklusion kan man sige, at det handler om mange bække små. Du får ikke flere tusind nye kvalitetsleads fra den ene dag til den anden, og slet ikke med kun ét værktøj.
            Men hvis du laver en god leadgenererings-strategi, kan du sikre et kontinuerligt flow af nye leads.
         </p>
         <p>
            Husk at give folk en god grund til at tilmelde sig. Det er faktisk det
            vigtigste tip af alle, for ellers får du svært ved at ramme en god konvertering. Det kan fx være noget content som nævnt i punkt 10. Det kan også være en konkurrence, en
            rabatkode eller andet, som er attraktivt for din målgruppe.
         </p>
         <p>
            Men pointen er, at der skal være en form for lokkemad. Hvis du bare skriver ”Tilmeld dig nyhedsbrevet og få gode
            tilbud”, så kan du desværre godt vinkel farvel til en høj konvertering.{" "}
         </p>
         <p>Kh. Kirstine</p>
      </BlogPostContainer>
   );
};

export default MoreSubscriptionsNewsletter;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import useContactForm from "./useContactForm";

const ContactForm = () => {
   const sendMessage = () => {
      fetch("/api/mail", {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
            Accepts: "application/json"
         },
         body: JSON.stringify(inputs)
      }).then(function (response) {
         if (response.status === 200) {
            clearInput();
            toast.success("Din besked er sendt! Mange tak", {
               className: "has-background-green has-text-white"
            });
         } else {
            toast.error("Indtast summen af 3 + 8 i det sidste felt", {
               className: "has-background-rose has-text-white"
            });
         }
      });
   };

   const { inputs, handleChange, handleSubmit, clearInput } = useContactForm(sendMessage);

   return (
      <React.Fragment>
         <form onSubmit={handleSubmit}>
            <div className="field">
               <div className="control has-icons-left">
                  <input
                     className="input"
                     type="text"
                     placeholder="Navn"
                     name="name"
                     onChange={handleChange}
                     value={inputs.name}
                     required
                  />
                  <span className="icon is-small is-left">
                     <FontAwesomeIcon icon="user" />
                  </span>
               </div>
            </div>
            <div className="field">
               <div className="control has-icons-left">
                  <input
                     className="input"
                     type="email"
                     placeholder="E-mail"
                     name="email"
                     onChange={handleChange}
                     value={inputs.email}
                     required
                  />
                  <span className="icon is-small is-left">
                     <FontAwesomeIcon icon="envelope" />
                  </span>
               </div>
            </div>
            <div className="field">
               <div className="control">
                  <textarea
                     className="textarea"
                     rows="8"
                     placeholder="Hvad kan jeg hjælpe dig med?"
                     name="message"
                     onChange={handleChange}
                     value={inputs.message}
                     required
                  />
               </div>
            </div>
            <div className="field is-grouped">
               <div className="control">
                  <input
                     className="input validation"
                     placeholder="3 + 8"
                     type="text"
                     name="validationResult"
                     onChange={handleChange}
                     value={inputs.validationResult}
                     required
                  />
               </div>
               <div className="control">
                  <button className="button is-primary" type="submit">
                     Send nu
                  </button>
               </div>
            </div>
         </form>
      </React.Fragment>
   );
};

export default ContactForm;

import React from "react";
import { Helmet } from "react-helmet";
import AboutMeContainer from "../../Components/AboutMeContainer";
import ContactContainer from "../Contact/ContactContainer";
import competences from "../../_data/_competency.json";

const CompetencySubPage = ({ match }) => {
	const competence = competences.find(comp => comp.link === match.params.link);
	return (
		<React.Fragment>
			<Helmet>
				<title>{competence.title}</title>
			</Helmet>
			<section className="section">
				<div className="container">
					<h1 className="title">{competence.title}</h1>
					<div dangerouslySetInnerHTML={{ __html: competence.text }} />
				</div>
			</section>
			<AboutMeContainer />
			<ContactContainer />
		</React.Fragment>
	);
};

export default CompetencySubPage;

import React from "react";

const BlogPostContainer = props => {
	return (
		<React.Fragment>
			<div className="section">
				<div className="container">
					<div className="columns">
						<div className="column is-10 is-offset-1 has-background-white">
							<div className="content blog-content">{props.children}</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default BlogPostContainer;

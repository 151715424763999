import React from "react";
import { Helmet } from "react-helmet";
import BlogCard from "./BlogCard";

const BlogListPage = () => {
   let tekstmuren = require("../../_images/tekstmuren/tekstmuren.jpg");
   let navle = require("../../_images/navle-princippet/noice.jpg");
   let tremails = require("../../_images/tre-automatiserede-mails/3_automatiserede_mails.jpg");
   let blackfriday = require("../../_images/6-alternative-black-friday-kampanger/black-friday-1.jpg");
   let dinekunder = require("../../_images/dine-kunder-vil-elske-den-her-overskrift/yeah.jpg");
   let landingside = require("../../_images/top-konventerende-landingsside/landingsside.jpg");
   let nyhedsbrev = require("../../_images/flere-tilmeldinger-til-nyhedsbrev/nyhedsbrev.jpg");
   return (
      <React.Fragment>
         <Helmet>
            <title>Gratis guldkorn</title>
         </Helmet>
         <section className="section blog-banner is-hidden-touch">
            <div className="container">
               <div className="columns">
                  <div className="column is-8">
                     <div className="content">
                        <h1 className="title is-1">Gratis guldkorn</h1>
                        <p className="">
                           Jeg blogger lidt en gang imellem. Ingen historier om ferie eller dagens outfit, men derimod konkrete råd til din markedsføring.
                           Læs og lær – det er ganske gratis.
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section className="section is-hidden-desktop">
            <div className="container">
               <div className="columns">
                  <div className="column is-8">
                     <div className="content">
                        <h1 className="title is-1">Gratis guldkorn</h1>
                        <p className="">
                           Jeg blogger lidt en gang imellem. Ingen historier om ferie eller dagens outfit, men derimod konkrete råd til din markedsføring.
                           Læs og lær – det er ganske gratis.
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section className="section content-section">
            <div className="container">
               <div className="columns">
                  <div className="column">
                     <div className="tile is-ancestor is-vertical">
                        <BlogCard
                           link="tekstmuren"
                           title="Hvor skræmmende er dine webtekster?"
                           date="Læsetid: 4-5 minutter"
                           text="Muren af tekst, der bare fortsætter og fortsætter i det uendelige, før der endelig kommer et linjeskift eller en overskrift... Undgå læserflugt med 7 simple tips."
                           imgurl={tekstmuren} />
                        <BlogCard
                           link="saadan-laver-du-en-topkonverterende-landingsside"
                           title="Sådan laver du en topkonverterende landingsside"
                           date="Læsetid: 3-4 minutter"
                           text="Kender du det her? Du har styr på din online annoncering, din SEO spiller max og din landingsside har god trafik, men den konverterer ikke. Eller i hvert fald ikke så godt, som du gerne vil have."
                           imgurl={landingside} />
                        <BlogCard
                           link="seks-alternative-black-friday-kampagner"
                           title="6 alternative Black Friday kampagner (uden tilbud!)"
                           date="Læsetid: 5-6 min"
                           text="Årets største handelsdag nærmer sig med hastige skridt, og mange virksomheder er allerede godt i gang med at skaffe varer, der kan sælges med stor rabat. Men hvad med alle dem, der ikke ønsker at være en del af forbrugsfesten?"
                           imgurl={blackfriday} />
                        <BlogCard
                           link="navle-princippet"
                           title="Navle-princippet: Sådan trænger du gennem lydmuren"
                           date="Læsetid: 3-4 min"
                           text="Nettet er fyldt med støj af den larmende, blinkende og forstyrrende slags. Hvordan trænger du gennem lydmuren og sikrer, at netop dine budskaber bliver hørt?"
                           imgurl={navle} />

                     </div>
                  </div>
                  <div className="column">
                     <div className="tile is-ancestor is-vertical">
                        <BlogCard
                           link="tre-automatiserede-mails"
                           title="3 automatiserede mails, som alle bør sende (ja, også dig)"
                           date="Læsetid: 4-5 minutter"
                           text="Automatiserede mails er en vidunderlig opfindelse, som desværre er overset af mange. Muligvis fordi det kræver tid at få sat op. Men faktisk er den smule tid det tager, en af de allerbedste investeringer, du kan gøre."
                           imgurl={tremails} />
                        <BlogCard
                           link="dine-kunder-vil-elske-den-her-overskrift"
                           title="Dine kunder vil elske den her overskrift"
                           date="Læsetid: 4-5 minutter"
                           text="Ja altså, du skal ikke kopiere min ord for ord, men i stedet følge de råd, der kommer om lidt :-) Først vil jeg gerne lige slå et slag for, hvorfor du overhovedet skal bruge tid på at optimere din overskrift."
                           imgurl={dinekunder} />
                        <BlogCard
                           link="faa-flere-tilmeldinger-til-dit-nyhedsbrev-nu"
                           title="Få flere tilmeldinger til dit nyhedsbrev. Nu."
                           date="Læsetid: 4-5 minutter"
                           text="Når du nu bruger tid på at skrive et nyhedsbrev, er det rart med så mange modtagere som muligt. Og hvordan får du så det? Der er mange veje til at udvide listen – her får du mine 10 favoritter."
                           imgurl={nyhedsbrev} />
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </React.Fragment>
   );
};

export default BlogListPage;

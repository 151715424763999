import React from "react";

const BlogPostDateAndReadtime = ({ date, readtime }) => {
	return (
		<p className="is-italic blog-date">
			{date} &middot; {readtime}
		</p>
	);
};

export default BlogPostDateAndReadtime;

import React from "react";
import { Helmet } from "react-helmet";
import ContactContainer from "./ContactContainer";

const ContactPage = () => {
   return (
      <React.Fragment>
         <Helmet>
            <title>Kontakt</title>
         </Helmet>
         <section className="section contact-banner">
            <div className="container">
               <div className="columns">
                  <div className="column is-10">
                     <div className="content has-text-white">
                        <h1 className="title is-1 has-text-white">Dig og mig – skal vi snakke sammen?</h1>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <ContactContainer />
      </React.Fragment>
   );
};

export default ContactPage;

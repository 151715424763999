import React from "react";
import { Link } from "react-router-dom";

const HeroContainer = () => {
   return (
      <section className="hero is-custom-hero hero-element">
         <div className="hero-body is-hidden-touch">
            <div className="container">
               <div className="columns">
                  <div className="column is-10">
                     <h1 className="title is-1 is-family-secondary has-text-white-bis">Topkonverterende tekster til web, sociale medier og e-mails</h1>
                     <h2 className="subtitle has-text-white-bis block hero-subtitle">
                        Jeg hjælper små og mellemstore virksomheder med fantastiske ord, der fanger modtagerne og får dankortet frem.
                     </h2>
                     <Link to="/kontakt" className="button frontpage-dark has-text-white is-medium call-to-action">
                        Kom med i klubben
                     </Link>
                  </div>
               </div>
            </div>
         </div>
         <div className="hero-body is-hidden-desktop">
            <div className="container">
               <div className="columns">
                  <div className="column is-10">
                     <h1 className="title is-3 is-family-secondary has-text-white-bis">Topkonverterende tekster til web, sociale medier og e-mails</h1>
                     <h2 className="subtitle has-text-white-bis block hero-subtitle">
                        Jeg hjælper små og mellemstore virksomheder med fantastiske ord, der fanger modtagerne og får dankortet frem.
                     </h2>
                     <Link to="/kontakt" className="button frontpage-dark has-text-white is-medium call-to-action">
                        Kom med i klubben
                     </Link>
                  </div>
               </div>
            </div>
         </div>
      </section>

   );
};

export default HeroContainer;

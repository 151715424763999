import React from "react";
import { Helmet } from "react-helmet";
import BlogPostContainer from "../BlogPostContainer";
import BlogPostDateAndReadtime from "../BlogPostDateAndReadtime";
import BlogPostSubheader from "../BlogPostSubheader";

const Tekstmuren = () => {
   const tekstmuren = require("../../../_images/tekstmuren/tekstmuren.jpg");

   return (
      <BlogPostContainer>
         <Helmet>
            <title>Hvor skræmmende er dine webtekster? Undgå læserflugt med 7 simple tips.</title>
         </Helmet>
         <h1 className="title">Hvor skræmmende er dine webtekster? Undgå læserflugt med 7 simple tips.</h1>
         <BlogPostDateAndReadtime date="24. februar 2023" readtime="Læsetid: 4-5 minutter" />
         <figure className="image">
            <img src={tekstmuren} alt="Tekstmuren" />
         </figure>
         <p><strong>Bang! Så ramte hovedet muren.</strong>
         </p>
         <p>
            Muren af tekst, der bare fortsætter og fortsætter i det uendelige, før der endelig kommer et linjeskift eller en overskrift.
         </p>
         <p>
            Den slags skræmmer dine kunder væk. De orker ikke at læse videre, hvis teksten ser uoverskuelig og kedelig ud, og det tager dem kun et splitsekund at dømme, om din tekst er tiden værd.
         </p>
         <p>Selve ordene har selvfølgelig også stor betydning. Det er alfa og omega, at du starter med en <a href="blog/dine-kunder-vil-elske-den-her-overskrift">spændende overskrift</a>,
            men en god overskrift og en interessant brødtekst kan ikke stå alene.</p>
         <p><strong>Du skal også tænke på layoutet.</strong>
         </p>
         <p>
            Jeg møder ofte tekstmuren, når jeg surfer rundt på nettet – især når jeg bruger mobilen. Her skal der ikke ret meget til, før et afsnit fylder hele skærmen, og det er ikke en fed oplevelse.
         </p>
         <p>
            Heldigvis kan du nemt skabe en læsevenlig tekst, der fastholder kunderne og giver dem lyst til at læse videre.
         </p>
         <p>
            Her kommer 7 simple tips, der får dig i mål:
         </p>
         <BlogPostSubheader text="1) Lav korte afsnit med hyppige linjeskift" />
         <p>Det her giver næsten sig selv. Husk at bryde teksten op i korte afsnit, så der kommer luft imellem. Tjek altid mobil vs. desktop og find en god balance, hvor det ser pænt ud på tværs af devices.</p>
         <BlogPostSubheader text="2) Skift mellem korte og lange sætninger" />
         <p>Du kan også gøre selve afsnittene mere indbydende og overskuelige ved at skifte mellem korte og lange sætninger. Prøv at sætte en ultrakort sætning på en linje for sig. Det giver den ekstra pondus og skaber god luft i teksten.</p>
         <BlogPostSubheader text="3) Brug punktopstilling" />
         <p>Med en punktopstilling giver du din læser et tiltrængt pusterum. Ahh, her er der luft!</p>
         <p>Men det er ikke den eneste grund til at bruge punkter. Punktopstillinger er nemlig også et godt værktøj til at sætte fokus på særligt udvalgte budskaber.</p>
         <p>Mange skimmer bare teksten og læser ikke ord for ord, men en punktopstilling fanger øjet. Det er altså her du skal fyre de ekstra gode guldkorn af, så selv de mest utålmodige læsere får dem med.</p>
         <BlogPostSubheader text="4) Brug underoverskrifter" />
         <p>Alt efter længden af din tekst er det en god idé at indsætte en eller flere underoverskrifter. På den måde får du brudt teksten op og indsat naturlige pauser, hvor du introducerer din næste pointe.</p>
         <BlogPostSubheader text="5) Indsæt billeder" />
         <p>Billeder er en sikker vej til at gøre læseoplevelsen mere spændende. De fanger øjet og skaber liv, derfor er det altid en god idé med et billede øverst på siden.</p>
         <p>Skriver du en længere guide eller andet, kan du med fordel flette et par billeder ind løbende, så der også er noget spændende for øjet i midten og til slut.</p>
         <BlogPostSubheader text="6) Fremhæv nøgleord med fed" />
         <p>Du kan også bryde muren af tekst ved hjælp af udvalgte markeringer med fed. På den måde får du både fremhævet vigtige nøgleord og skabt en kærkommen afbrydelse i strømmen af ens sætninger, der afløser hinanden.</p>
         <BlogPostSubheader text="7) Slet alle unødvendige detaljer" />
         <p>Dagens sidste tip: Skær ind til benet og sørg for, at du kun har relevant information med i din tekst.</p>
         <p>En webtekst kan sagtens være lang, men det kræver, at den fanger modtageren hele vejen igennem. Så frem med slettelakken og drop alt udenomssnak, der ikke giver værdi.</p>
      </BlogPostContainer>
   );
};

export default Tekstmuren;

import React from "react";
import { Link } from "react-router-dom";

const BlogLinkBox = ({ link, text, isExternal }) => {
  if (isExternal) {
    return (
      <div className="box is-full  has-background-green-light has-text-centered">
        <a className="has-text-white-bis" href={link} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      </div>
    );
  } else {
    return (
      <div className="box is-full has-background-green-light has-text-centered">
        <Link className="has-text-white-bis" target="_blank" to={link}>
          {text}
        </Link>
      </div>
    );
  }
};

export default BlogLinkBox;

import React from "react";

const CustomerFlowHeroContainer = () => {
   return (
      <section className="hero is-custom-hero hero-element">
         <div className="hero-body">
            <div className="container">
               <div className="columns">
                  <div className="column is-6 is-offset-3">
                     <h1 className="title customerflow-header-size is-family-secondary has-text-white-bis">Få mere ud af dit budget. Nu.</h1>
                     <h2 className="subtitle has-text-white-bis customerflow-hero-space">
                        Jeg optimerer din markedsføring, så du ikke går glip af potentiel omsætning. Netop nu sparer du 50% på
                        min hjælp.*
							</h2>
                     <a href="mailto:kirstine@marketingmedmere.dk" className="button has-background-primary has-text-white is-medium call-to-action customerflow-hero-space">
                        Ja tak til billig timepris
							</a>
                  </div>
               </div>
            </div>
         </div>
      </section>
   );
};

export default CustomerFlowHeroContainer;

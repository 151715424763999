import React from "react";
import CompetencyTile from "./CompetencyTile";
import competences from "../../_data/_competency.json";

const CompetencyContainer = () => {
	return (
		<section className="section">
			<div className="container">
				<h1 className="title">Jeg hjælper med</h1>
				{/* <div className="columns is-8 is-multiline is-variable">
					{competences.map(comp => (
						<CompetencyCard key={comp.link} link={comp.link} title={comp.title} background={comp.background} />
					))}
				</div> */}
				<div className="tile is-ancestor">
					<div className="tile is-4 is-vertical is-parent">
						<CompetencyTile
							link={competences[0].link}
							background={competences[0].background}
							title={competences[0].title}
						/>
						<CompetencyTile
							link={competences[1].link}
							background={competences[1].background}
							title={competences[1].title}
						/>
					</div>
					<div className="tile is-4 is-vertical is-parent">
						<CompetencyTile
							link={competences[2].link}
							background={competences[2].background}
							title={competences[2].title}
						/>
						<CompetencyTile
							link={competences[3].link}
							background={competences[3].background}
							title={competences[3].title}
						/>
					</div>
					<div className="tile is-4 is-vertical is-parent">
						<CompetencyTile
							link={competences[4].link}
							background={competences[4].background}
							title={competences[4].title}
						/>
						<CompetencyTile
							link={competences[5].link}
							background={competences[5].background}
							title={competences[5].title}
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default CompetencyContainer;

import React from 'react'

const CustomerFlowEmailContainer = () => {
   return (
      <React.Fragment>

         <section className="section">
            <div className="container">
               <div className="columns">
                  <div className="column is-10 is-offset-1">
                     <h1 className="title">Spilder du tid og penge på dårlig markedsføring?</h1>
                     <div className="content">
                        <p>Det er super ærgerligt at bruge ressourcer på mails, annoncer, landingssider og blogindlæg, som ikke
                     performer optimalt. Spild af penge, spild af tid og ubeskriveligt utilfredsstillende.</p>
                        <p>
                           Jeg kan hjælpe dig med at få mest muligt ud af dit markedsføringsbudget, og jeg kan gøre det til så lav en
                           timepris, at du næsten får det gratis.
                           Normalt koster min hjælp mellem 895 og 695 i timen. Lige nu får du den til kun 350 kr. (ex moms), og
                           dermed sparer du altså mindst 50% på min rådgivning. Det er så billigt, at jeg næsten har fortrudt, men
                           here goes…
                  </p>
                        <p>
                           350 kroner, det er altså ikke meget.
                  </p>
                        <p>
                           For den sjat får du ikke engang en ny kaffemaskine til kontoret (medmindre du køber den allerbilligste
                           slags, som laver virkelig dårlig kaffe). Til gengæld kan du få:
                  </p>
                        <p>
                           <strong>60 minutters sparring,</strong> der hjælper dig med at optimere din markedsføring, så du kan spare både tid og
                     penge fremover
                  </p>
                        <p>eller</p>
                        <p><strong>3 x produkttekst,</strong>der beskriver dine produkter så malerisk, at det bliver næsten umuligt ikke at klikke dem
                     hjem
                  </p>
                        <p>
                           eller
                  </p>
                        <p><strong>2 x Facebook opslag,</strong>der rent faktisk får dine følgere til at engagere sig</p>
                        <p><strong>Dårlig kaffe optimerer ikke din markedsføring. Det gør min hjælp til gengæld.</strong></p>
                        <br />
                        <div className="box is-full  has-background-primary has-text-centered">
                           <a className="has-text-white-bis" href="mailto:kirstine@marketingmedmere.dk" target="_blank" rel="noopener noreferrer">
                              Ja tak – jeg vil gerne have billig hjælp til at optimere min markedsføring
                           </a>
                        </div>
                        <p>Og så til det med betingelserne:</p>
                        <p>*Tilbuddet om en timepris på 350 kr. ex moms gælder i 48 timer. Minimumskøb er 1 time – max antal timer
                        til denne pris er 5 stk.
                  </p>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </React.Fragment>
   )
}

export default CustomerFlowEmailContainer;

import React from "react";
import { Helmet } from "react-helmet";
import ReferenceContainer from "./ReferenceContainer";
import ContactContainer from "../Contact/ContactContainer";

const ReferencePage = () => {
	return (
		<React.Fragment>
			<Helmet>
				<title>Referencer</title>
			</Helmet>
			<ReferenceContainer showHeader={true} />
			<ContactContainer />
		</React.Fragment>
	);
};

export default ReferencePage;

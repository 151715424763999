import React from "react";

const AboutMeContainer = () => {
   const img = require("../_images/about_me.png");
   return (
      <section className="section content-section">
         <div className="container">
            <h1 className="title has-text-centered">Bag skærmen</h1>
            <div className="is-divider-thick block"></div>
            <div className="content">
               <div className="tile">
                  <div className="tile is-4">
                     <figure className="">
                        <img src={img} alt="Kirstine" />
                     </figure>
                  </div>
                  <div className="tile is-vertical">
                     <p>
                        Der sidder jeg, Kirstine Assenholm. Typen, der elsker ord, chokolade og smuk natur.
                     </p>
                     <p>
                        Jeg startede som soloselvstændig i 2019 og har hjulpet en masse forskellige virksomheder med tekstforfatning, contentplaner og strategisk rådgivning lige siden.
                     </p>
                     <p>
                        Før det arbejdede jeg som Brand Manager. Jeg gik til møder dagen lang og var med i mange spændende projekter, men jeg savnede tekstnørderiet.
                     </p>
                     <p>Jeg elsker at lege med ord, at tænke kreativt og finde den vinkel, der gør produktet eller historien spændende. Det er det, jeg brænder for og det, jeg lever af.</p>

                  </div>
               </div>
            </div>
         </div>
      </section>
   );
};

export default AboutMeContainer;

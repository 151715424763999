import React from "react";
import { Helmet } from "react-helmet";
import BlogPostContainer from "../BlogPostContainer";
import BlogPostDateAndReadtime from "../BlogPostDateAndReadtime";
import BlogLinkBox from "../BlogLinkBox";
import BlogPostSubheader from "../BlogPostSubheader";

const NavlePrincippet = () => {
   const noice = require("../../../_images/navle-princippet/noice.jpg");

   return (
      <BlogPostContainer>
         <Helmet>
            <title>Navle-princippet: Sådan trænger du gennem lydmuren</title>
         </Helmet>
         <h1 className="title blog-title">Navle-princippet: Sådan trænger du gennem lydmuren</h1>
         <BlogPostDateAndReadtime date="6. maj 2019" readtime="Læsetid: 3-4 min" />
         <figure className="image">
            <img src={noice} alt="Støj" />
         </figure>
         <p>Nettet er fyldt med støj af den larmende, blinkende og forstyrrende slags. Hvordan trænger du gennem lydmuren og sikrer, at netop dine budskaber bliver hørt?</p>
         <p>
            Jo, det gør du ved at bruge navle-princippet. Det er et begreb, jeg lige har opfundet, men ikke desto mindre noget, som jeg har arbejdet ud fra i mange år. Det handler
            ganske simpelt om dine kunders navler. Ikke sådan rent fysisk (gudskelov), men mere i overført betydning.
         </p>
         <p>
            <strong>
               Alle mennesker elsker navlepilleri.</strong>
         </p>
         <p>Vi elsker ting, som vi kan relatere direkte til. Ting, der løser vores problemer, taler til vores dybeste frygt eller vores hedeste
            drømme.
         </p>
         <p>
            Vi elsker til gengæld ikke information, som vi finder overflødig, uinteressant eller direkte kedelig. Den slags orker vi ikke, for vi har så travlt så travlt med at
            arbejde, slappe af og lave gode madpakker til vores børn.
         </p>
         <p>Prøv lige at se den her nyhed, som jeg fandt på nettet i morges:</p>
         <p>”[Virksomhed]-partner vinder kongelig hæderspris”</p>
         <p>ZZZzznork…</p>
         <p>Ja ja, I er super gode, og det er jeres samarbejdspartner åbenbart også. Fint for jer og kedeligt for mig.</p>
         <p>
            Jeg har ikke brug for at læse om en virksomheds succeser – i hvert fald ikke på den måde. Jeg vil kun have nyheder, der er spændende for mig. Jeg er nemlig et helt
            almindeligt menneske anno 2019, som hellere vil bruge min tid på andet end at læse kedelige artikler om virksomheder, der vinder priser.
         </p>
         <BlogPostSubheader text="SÅ HVAD ER PROBLEMET?" />

         <p>
            Jo altså, dit problem er måske dårligt salg, høj bounce, lave konverteringer eller høje klikpriser. Men det interessante er faktisk nærmere, hvilke problemer dine kunder
            har, og hvordan din virksomhed kan hjælpe.
         </p>
         <p>
            Uanset om du skriver blogindlæg, mails, So-Me-opdateringer, annoncetekster eller hvad det nu kan være, så skal du altid fokusere på læseren. Du skal skifte fokus fra ”hvad
            har jeg på hjerte?” til ”hvordan taler jeg til mine kunder?”.
         </p>
         <p>
            Måske har du netop lanceret et produkt, som er teknisk avanceret og mega sejt. Godt for dig. Det må du gerne fortælle dine kunder, men du skal gøre det på den rigtige måde.
            Du skal vinkle din nyhed, så den taler lige ind i den indre narcissist, som gemmer sig i alle mennesker. Du skal besvare spørgsmålet ”what’s in it for me?”.
         </p>
         <p>
            Lad os nu sige, at du skal lancere en ny app med masser af inspiration til madlavning, madpakker og madplaner. Du synes selv, at jeres interface er super brugervenligt, og
            det vil du gerne fremhæve. Derfor skriver du noget a la ”Få inspiration til ugens måltider med verdens nemmeste app”.
         </p>
         <p>
            <strong>Er det relevant på den navlepillende måde? Nej, ikke rigtigt.</strong>
         </p>
         <p>Inspiration er fint ja, men nettet er fyldt med inspiration, så hvorfor skal jeg vælge at tjekke netop dit materiale?</p>
         <p>Verdens nemmeste app lyder også dejligt, men hvordan hjælper det mig? Sparer jeg tid eller penge? Eller får jeg færre grå hår, fordi det hele kører som smurt?</p>
         <p>Hvis du nu skrev det her i stedet: ”Appen, der får dine børn til selv at smøre deres madpakker – selvom de hader sølvpapir”.</p>
         <p>
            Så taler du direkte ind i et problem, som mange kender. Ungerne er ved at være store nok til selv at fikse deres madpakker, men de er luddovne og vil hellere spille
            computer end at smøre leverdreng på en skive rugbrød. Derfor står mor og far aften efter aften og laver madpakker, mens de snakker om, at det også snart skal være slut.
         </p>
         <p>
            <strong>Kan du se forskellen?</strong>
         </p>
         <p>I den sidste version løser du et problem for læseren, og derfor bliver dit produkt langt mere interessant (hvis kunden kan nikke
            genkendende til netop det problem altså).
         </p>
         <p>
            Det er nemmere sagt end gjort sådan at omstille hjernen til at droppe de nyheder, som man brænder for at fortælle, og i stedet kun formidle dem, der giver værdi for
            modtageren.
         </p>
         <p>
            Heldigvis for dig, så har jeg øvet mig i mange år, og jeg hjælper dig gerne på vej. Jeg både skriver og redigerer tekster, så de bliver knivskarpe og interessante for
            modtageren.
         </p>
         <p>Og se nu her. Min tekst var åbenbart spændende nok til at lokke dig helt ned til min call-to-action :-)</p>
         <BlogLinkBox link="/kontakt" text="Ja tak – jeg vil gerne trænge gennem lydmuren" isExternal={false} />
      </BlogPostContainer>
   );
};

export default NavlePrincippet;

import React from "react";
import { Helmet } from "react-helmet";
import ContactContainer from "../Contact/ContactContainer";
import PricingContainer from "./PricingContainer";

const PricingPage = () => {
	return (
		<React.Fragment>
			<Helmet>
				<title>Priser</title>
			</Helmet>
			<PricingContainer />
			<ContactContainer />
		</React.Fragment>
	);
};

export default PricingPage;

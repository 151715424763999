import { useState } from "react";

const useMenu = () => {
	const [toggles, setToggle] = useState({ burger: false, kompetencer: false });

	const toggleBurger = () => {
		setToggle(toggles => ({ ...toggles, burger: !toggles.burger, kompetencer: false }));
	};

	const toggleKompetencer = () => {
		setToggle(toggles => ({ ...toggles, kompetencer: !toggles.kompetencer }));
	};

	const onMouseEnter = () => {
		setToggle(toggles => ({ ...toggles, kompetencer: true }));
	};

	const onMouseLeave = () => {
		setToggle(toggles => ({ ...toggles, kompetencer: false }));
	};

	return { toggleBurger, toggleKompetencer, onMouseEnter, onMouseLeave, toggles };
};

export default useMenu;

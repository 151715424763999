import React from "react";
import { Link } from "react-router-dom";
import useMenu from "./useMenu";

const Menu = () => {
   const { toggleBurger, toggles } = useMenu();
   const img = require("../_images/Logo_lang.png");

   return (
      <React.Fragment>
         <nav className="navbar is-link is-medium is-fixed-top">
            <div className="container">
               <div className="navbar-brand">
                  <Link to="/" className="navbar-item navbar-brand-logo is-hidden-touch" />
                  <Link to="/" className="navbar-item is-hidden-desktop">
                     <img src={img} className="brand-logo" alt="Logo" />
                  </Link>
                  <div role="button" className="navbar-burger burger" aria-label="menu" aria-expanded="false" onClick={toggleBurger}>
                     <span aria-hidden="true" />
                     <span aria-hidden="true" />
                     <span aria-hidden="true" />
                  </div>
               </div>
               <div className={toggles.burger ? "is-active navbar-menu" : "navbar-menu"}>
                  <div className="navbar-end">
                     <Link to="/" className="navbar-item is-uppercase" onClick={toggleBurger}>
                        Forside
                     </Link>
                     <Link to="/gratis-guldkorn" className="navbar-item is-uppercase" onClick={toggleBurger}>
                        Gratis guldkorn
                     </Link>
                     <Link to="/priser" className="navbar-item is-uppercase" onClick={toggleBurger}>
                        Priser
                     </Link>
                     <Link to="/kontakt" className="navbar-item is-uppercase" onClick={toggleBurger}>
                        Kontakt
                     </Link>
                  </div>
               </div>
            </div>
         </nav>
      </React.Fragment>
   );
};

export default Menu;

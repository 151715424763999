import React from "react";

const Reference = ({ reference, author, imgStr, companyLink, companyName }) => {
  const img = require("../../_images/references/" + imgStr);
  return (
    <div className="tile is-parent is-4">
      <div className="tile is-child has-text-centered">
        <figure className="image is-128x128 quote-image">
          <img className="is-rounded black-white" src={img} alt="profile_pic" />
        </figure>
        <div className="is-italic">{reference}</div>
        <div>
          <span className="has-text-weight-semibold">{author}</span>,{" "}
          <a href={companyLink} target="_blank" rel="noopener noreferrer">
            {companyName}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Reference;

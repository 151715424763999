import React from "react";
import { Helmet } from "react-helmet";
import BlogPostContainer from "./Blog/BlogPostContainer";

const CookiesAndPersonalData = () => {
   return (
      <BlogPostContainer>
         <Helmet>
            <title>Cookies og persondata</title>
         </Helmet>
         <h1 className="title">Cookies</h1>
         <p>Hjemmesiden her benytter cookies, som bliver brugt til at indsamle anonym information om alle
            besøgende.</p>
         <p>
            Cookies er en tekstfil, som gemmes på din computer, mobil eller tablet med det formål at huske indstillinger og
            udføre statistik. Cookies kan ikke indeholde skadelig kode som fx virus.
			</p>
         <p>
            Hvis du ikke vil acceptere mine cookies, er du velkommen til at forlade min hjemmeside igen eller slå cookies
            fra i din browser.
			</p>
         <p>
            NYHEDSBREV
         </p>
         <p>
            Tilmelder du dig mit nyhedsbrev, så giver du samtidig tilladelse til, at jeg må sende markedsføringstips og
            tilbud til dig via mail. Du kan altid trække dit samtykke tilbage ved at afmelde dig nyhedsbrevet. Det kan du
            gøre ved at sende mig en mail på <a href="mailto:kirstine@marketingmedmere.dk">kirstine@marketingmedmere.dk</a> eller ved at klikke på afmeldingslinket i
            bunden af mine mails.
         </p>
         <p>
            Jeg anvender kun dine oplysninger til det formål, de er indsamlet til, hvilket vil sige nyhedsbrevet.
         </p>
         <p>
            Al persondata bliver behandlet i overensstemmelse med GDPR. Hvis du gerne vil se, hvilke oplysninger jeg
            har lagret om dig, er du velkommen til at sende mig en mail.
         </p>
         <p>
            Vil du klage over min behandling af dine personoplysninger, så skal du skrive til Datatilsynet.
         </p>
      </BlogPostContainer>
   );
};

export default CookiesAndPersonalData;

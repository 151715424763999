import React from "react";
import ContactForm from "./ContactForm";

const ContactContainer = () => {
   return (
      <section className="section content-section">
         <div className="container">
            <div className="columns is-variable is-8">
               <div className="column is-half">
                  <ContactForm />
               </div>
               <div className="column is-half">
                  <div className="content">
                     <p>
                        Drømmer du om en bunke toplækre og konverterende tekster? Så send mig en mail eller en besked via formularen her,
                        hvor du forklarer lidt om din opgave. Derefter aftaler vi tid til en indledende snak over telefonen.
                     </p>
                     <p>
                        Hvorfor ikke bare ringe med det samme, tænker du måske, og det kunne i teorien også være en god idé.
                        Men min telefon ligger som regel i et helt andet rum med lyden slået fra.
                        På den måde kan jeg arbejde koncentreret og levere det bedst mulige resultat for mine kunder.
                     </p>
                     <p>
                        Det er jo også meget rart for dig, hvis du nu selv skulle ende med at takke ja til et samarbejde og gerne vil have mest mulig værdi for pengene.
                     </p>
                     <p>
                        Jeg glæder mig til at høre fra dig.
                     </p>
                     <p>Kh. Kirstine</p>
                     <p>
                        <a href="mailto:kirstine@marketingmedmere.dk">kirstine@marketingmedmere.dk</a>
                     </p>
                  </div>
               </div>
            </div>
         </div>
      </section>
   );
};

export default ContactContainer;

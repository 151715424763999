import React from "react";
import { Helmet } from "react-helmet";
import BlogPostContainer from "../BlogPostContainer";
import BlogPostDateAndReadtime from "../BlogPostDateAndReadtime";
import BlogPostSubheader from "../BlogPostSubheader";

const DineKunderVilElskeDenHerOverskrift = () => {
   const cake = require("../../../_images/dine-kunder-vil-elske-den-her-overskrift/cake.jpg");
   const yeah = require("../../../_images/dine-kunder-vil-elske-den-her-overskrift/yeah.jpg");
   const runner = require("../../../_images/dine-kunder-vil-elske-den-her-overskrift/runner.jpg");
   return (
      <BlogPostContainer>
         <Helmet>
            <title>Dine kunder vil elske den her overskrift</title>
         </Helmet>
         <h1 className="title">Dine kunder vil elske den her overskrift</h1>
         <BlogPostDateAndReadtime date="5. juni 2021" readtime="Læsetid: 4-5 minutter" />
         <figure className="image">
            <img src={yeah} alt="yeah" />
         </figure>
         <p>
            Ja altså, du skal ikke kopiere min ord for ord, men i stedet følge de råd, der kommer om lidt :-) Først vil jeg gerne lige slå et slag for, hvorfor du overhovedet skal
            bruge tid på at optimere din overskrift. Ofte prioriteres brødteksten, men det er synd og skam. Og spild af tid. For brødteksten er jo ikke meget værd, hvis folk springer
            fra allerede ved overskriften, og det er der desværre mange, der gør.
         </p>
         <p>
            Derfor bør du altid sætte tid af til at formulere en uimodståelig overskrift, som gør, at din læser bare <i>må</i> tjekke resten. Og hvordan skriver du så den perfekte overskrift? Ved at følge nedenstående tips selvfølgelig.
         </p>
         <BlogPostSubheader text="1. Kort og godt" />
         <p>
            Det er vigtigt at skære ind til benet og undgå unødvendige fyldord, så overskriften ikke bliver for lang. Din overskrift skal fortælle læseren, hvad resten af teksten
            handler om – hverken mere eller mindre. Du skal altså hverken oversælge din tekst med en overskrift, der lover mere end den kan holde, eller undersælge ved at skrive noget,
            der er for kedeligt.
         </p>
         <p>
            Om du over- eller undersælger dit indhold kan være svært at vurdere. Det er ofte en smagssag, hvor kæk man vil være, men hvis vi tager udgangspunkt i min overskrift til
            blogindlægget her, så kunne jeg jo have valgt at skrive ”Overskriften der aldrig fejler – lær at skrive den her”. Sådan en overskrift er fuld af selvtillid og vil
            sandsynligvis få mange kliks, men der er samtidig stor risiko for at skuffe læseren ved at love noget, som jeg ikke kan holde. Derfor gik jeg med en lidt mere beskeden
            version.
         </p>
         <BlogPostSubheader text="2. Pir dine læseres nysgerrighed" />
         <p>
            Dette tip gælder for alle former for kommunikation og derfor også en overskrift. Hvem skal læse din tekst, og hvilke triggere kan du bruge for at lokke dem videre fra
            overskriften?
         </p>
         <p>
            Fristende overskrifter bygger ofte på følelser som frygt, håb eller tvivl. Vælger vi eksempelvis frygten, kunne det være noget a la ”Lær at løbe rigtigt, før det er for
            sent”. Uha uha, tænk hvis du løb forkert hele livet – konsekvenserne er muligvis fatale, og derfor bliver man nysgerrig på svaret.
         </p>
         <figure className="image">
            <img src={runner} alt="runner" />
         </figure>
         <BlogPostSubheader text="3. Skab værdi" />
         <p>
            Udover nysgerrighed er det også godt at fokusere på den værdi, som du skaber for læseren. Det giver folk en plausibel grund til at læse videre og ofre sparsom tid på at
            læse netop din tekst.
         </p>
         <p>
            Du kan fx skabe værdi ved at love læseren, at de efter at have læst din tekst har lært noget. Den slags overskrifter starter ofte med ”Derfor” eller ”Sådan”. Du kan også
            med fordel bruge ordet ”Guide”. Min overskrift til indlægget her kunne sagtens laves om til ”Guide: Sådan skriver du en overskrift, der fanger. ”
         </p>
         <BlogPostSubheader text="4. Brug rim og remser" />
         <p>
            Væk med unødvendige fyldord, og ind med rim og remser. Du kan fx give din overskrift en god rytme ved at bruge bogstavrim som dette: ”3 fikse fletninger din datter vil
            elske”.
         </p>
         <BlogPostSubheader text="5. Vær personlig" />
         <p>Du kan med fordel bruge ”du”, ”dig” og ”din” i stedet for ”man”, da personlig kommunikation konverterer bedre. Prøv at se forskellen på overskrifterne her:</p>
         <ul>
            <li>Kunderne vil elske den her overskrift</li>
            <li>Dine kunder vil elske den her overskrift</li>
         </ul>
         <p>Den øverste er nem at distancere sig fra, da ”kunderne” jo kan referere til alt muligt og ikke nødvendigvis dine kunder.</p>
         <BlogPostSubheader text="6. Lav en liste" />
         <p>
            Dine læsere elsker tal. Ikke nødvendigvis på revisormåden, hvor man surfer rundt i en pivottabel dagen lang, men derimod som en del af din overskrift. Tal og lister
            fungerer super godt i overskrifter, fordi de gør indholdet konkret for dine læsere og skaber en forventning om en letlæselig og overskuelig tekst.
         </p>
         <p>Du har garanteret set masser af den slags overskrifter allerede. Det er sætninger, der lyder cirka sådan her: ”10 tips til dig, der [noget du gerne vil have]”.</p>
         <BlogPostSubheader text="7. Choker med overraskende påstande" />
         <p>
            Det her tip er til de modige. Det kræver nemlig, at du tør ruske lidt op i tingene og skrive om en uventet udvikling, en kontroversiel holdning eller andet, som får dine
            læsere lidt op på barrikaden. Husk i øvrigt at din brødtekst skal indeholde argumenter for dine påstande. Ellers er din overskrift ren click-bait.
         </p>
         <p>Men altså, eksempler på overraskende overskrifter kunne være:</p>
         <ul>
            <li>Derfor skal dine børn ikke vaske hænder</li>
            <li>Spis kage hver dag og få en sund krop</li>
            <li>Direktør siger nej til millionordre</li>
         </ul>
         <p>Jeg har i øvrigt gode argumenter for alle tre, så det kan altså lade sig gøre.</p>
         <figure className="image">
            <img src={cake} alt="kage" />
         </figure>
         <BlogPostSubheader text="8. Glem ikke SEO" />
         <p>
            Hvis din overskrift skal bruges til et blogindlæg eller anden webtekst, skal du huske at inkludere dit primære søgeord. Det kan være fristende at vælge en super kreativ
            overskrift, der ikke indeholder søgeordet, men det er ærgerligt at gå glip af potentiel organisk trafik på den bekostning. Udelader du dit søgeord, risikerer du desuden at
            formulere en overskrift, der ikke fortæller læseren, hvad teksten handler om.
         </p>
         <p>
            En overskrift som ”Vil du høre en hemmelighed?” eller ”Undrer du dig også over det her?” vil få rigtig mange klik, men den har ingen SEO-værdi og vil formentlig også have
            høj bounce rate.
         </p>
         <BlogPostSubheader text="9. Brainstorm amok" />
         <p>
            Den perfekte overskrift kommer sjældent efter to minutters tænketid henover frokosten. Derfor er det godt med en decideret brainstorm, hvor du sætter dig for at formulere
            10-15 forskellige overskrifter. På den måde tvinger du dig selv til at være kreativ, og pludselig sidder du med en knivskarp overskrift.
         </p>
         <p>
            Nå, det var dagens tips. Skulle du være i tvivl, så anbefaler jeg altså ikke, at du bruger alle på én gang. For det første er det nok temmelig svært at skrive en tekst, der
            bygger på samtlige tips, og for det andet tror jeg ikke, at den bliver god. Så vælg det, der giver mening :-)
         </p>
         <p>God fornøjelse med skriveriet.</p>
         <p>Kh. Kirstine</p>
      </BlogPostContainer>
   );
};

export default DineKunderVilElskeDenHerOverskrift;

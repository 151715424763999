import React from "react";
import Reference from "./Reference";
import references from "../../_data/_references.json";

const ReferenceContainer = () => {
   return (
      <section className="section">
         <div className="container">
            <div className="tile is-ancestor">
               {references.map(reference => (
                  <Reference
                     key={reference.author}
                     reference={reference.text}
                     author={reference.author}
                     imgStr={reference.imgStr}
                     companyLink={reference.companyLink}
                     companyName={reference.companyName}
                  />
               ))}
            </div>
         </div>
      </section>
   );
};

export default ReferenceContainer;

import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
   return (
      <footer className="footer">
         <div className="content has-text-centered has-text-white">
            <span>&copy; Marketingmedmere</span>
            <span> | </span>
            <Link className="link has-text-white" to="/handelsbetingelser">
               Handelsbetingelser
            </Link>
            <span> | </span>
            <Link className="link has-text-white" to="/kontakt">
               Kontakt
            </Link>
         </div>
      </footer>
   );
};

export default Footer;

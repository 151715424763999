import { useState } from "react";

const useContactForm = callback => {
	const [inputs, setInputs] = useState({ name: "", email: "", message: "", validationResult: "" });

	const handleSubmit = event => {
		if (event) {
			event.preventDefault();
		}
		callback();
	};

	const handleChange = event => {
		event.persist();
		setInputs(inputs => ({ ...inputs, [event.target.name]: event.target.value }));
	};

	const clearInput = () => {
		setInputs(inputs => ({ ...inputs, email: "", name: "", message: "", validationResult: "" }));
	};

	return { handleSubmit, handleChange, clearInput, inputs };
};

export default useContactForm;

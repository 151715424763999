import React from "react";
import ContactForm from "../Contact/ContactForm";

const CustomerFlowContactContainer = () => {
   return (
      <section className="section">
         <div className="container">
            <div className="columns">
               <div className="column is-10 is-offset-1">
                  <div className="columns is-variable is-8">
                     <div className="column is-half">
                        <ContactForm />
                     </div>
                     <div className="column is-half">
                        <div className="content">
                           <h1 className="title">Derfor skal du kontakte mig</h1>
                           <p>
                              Erfaring og naturligt flair for opgaven gør en forskel, og jeg har begge dele. Jeg har arbejdet med strategisk
                              markedsføring og tekstforfatning i over 10 år. Jeg ved, hvad der virker, og jeg kan hjælpe dig med at
                              optimere din markedsføring, så du får mest muligt ud af din indsats.
							</p>
                           <p>
                              Tag imod mit tilbud om 50% rabat på konsulentydelser ved at ringe, skrive eller udfylde formularen her. Jeg
                              glæder mig til at høre fra dig.
                     </p>
                           <p>Kh. Kirstine</p>
                           <p>
                              <a href="mailto:kirstine@marketingmedmere.dk">kirstine@marketingmedmere.dk</a>
                              <br />
								+45 24 40 30 53
							</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   );
};

export default CustomerFlowContactContainer;
import React from "react";
import { Link } from "react-router-dom";

const CompetencyTile = ({ link, title, background }) => {
	return (
		<React.Fragment>
			<Link to={"kompetence/" + link} className={"tile competency-tile is-child box " + background}>
				<h1 className="subtitle is-uppercase has-text-white-bis has-text-centered">{title}</h1>
			</Link>
		</React.Fragment>
	);
};

export default CompetencyTile;

import React from "react";
import { Helmet } from "react-helmet";
import BlogPostContainer from "../BlogPostContainer";
import BlogPostDateAndReadtime from "../BlogPostDateAndReadtime";
import BlogPostSubheader from "../BlogPostSubheader";

const TopkonverterendeLandingsside = () => {
   let landingside = require("../../../_images/top-konventerende-landingsside/landingsside.jpg");
   return (
      <BlogPostContainer>
         <Helmet>
            <title>Sådan laver du en topkonverterende landingsside</title>
         </Helmet>
         <h1 className="title">Sådan laver du en topkonverterende landingsside</h1>
         <BlogPostDateAndReadtime date="1. august 2022" readtime="Læsetid: 3-4 minutter" />
         <figure className="image">
            <img src={landingside} alt="landingside.jpg" />
         </figure>
         <p>
            Kender du det her? Du har styr på din online annoncering, din SEO spiller max og din landingsside har god trafik, men den konverterer ikke. Eller i hvert fald ikke så godt,
            som du gerne vil have.
         </p>
         <p>Så følg med her og få mine 10 tips til at optimere din landingsside.</p>
         <BlogPostSubheader text="1. Brug aldrig din forside som landingsside" />
         <p>
            Din forside er designet til at byde folk velkommen. Den er ikke optimal som landingsside, fordi den vil en hel masse forskellige ting og dermed ikke har et klart fokus.
            Husk derfor altid at oprette en separat landingsside, når du starter en ny kampagne.
         </p>
         <BlogPostSubheader text="2. Skru ned for dine mål" />
         <p>
            Her mener jeg ikke, at du skal sænke dine forventninger, men derimod at du skal udvælge et enkelt mål for siden. Og kun ét. Det nytter ikke, hvis du både vil sælge
            produkter, få folk til at deltage i dit webinar samt tilmelde sig dit nyhedsbrev. En topkonverterende landingsside lægger kun op til én handling.
         </p>
         <BlogPostSubheader text="3. Drop din topmenu" />
         <p>
            Jo renere en landingsside, jo bedre. Når du har udvalgt den ene ting, som du gerne vil have dine besøgende til at gøre, så fjern alt det, der handler om noget andet. Hermed
            også din topmenu og navigation. Der er ingen grund til at distrahere med unødvendig information. Det handler både om at undgå støj og at sørge for, at brugeren bliver på
            netop denne side.
         </p>
         <BlogPostSubheader text="4. Brug en overskrift der fanger læseren" />
         <p>
            Din landingsside lever eller dør med din overskrift. Ja, det lyder måske lidt melodramatisk, men faktum er at du kun har et par sekunder til at fange folks opmærksomhed,
            før de smutter igen. Derfor skal din overskrift sidde lige i skabet.
         </p>
         <BlogPostSubheader text="5. Sørg for rød tråd mellem trafikkilder og landingsside" />
         <p>
            Tænk alle dine trafikkilder igennem og ensret materialet, så de spiller sammen med landingssiden. Genbrug overskrift, typografi, farvevalg og så videre, så dine besøgende
            let kan se, at de er havnet på den rigtige side. Ellers risikerer du en høj bounce rate.
         </p>
         <BlogPostSubheader text="6. Placer det vigtigste først" />
         <p>Selvom folk scroller mere og mere, er der altså stadig flest, der ser det i toppen. Derfor skal du placere de vigtigste elementer øverst på din side.</p>
         <BlogPostSubheader text="7. Fortæl folk, hvad de skal gøre og hvorfor" />
         <p>
            Hvis du virkelig skal have folk til at konvertere, så skal du lidt ind i psykologiens verden og se på, hvordan du bedst overbeviser dine besøgende om, at de skal gøre x
            eller y. Et godt trick er at sætte ord på den konkrete værdi, der følger med. Det kunne være noget a la: ”Det her er ikke bare et fitness-abonnement. Det er din garanti for
            at komme i form på 4 uger.”
         </p>
         <p>
            Tænk også på hvilke indvendinger dine besøgende kan have og sørg for at imødekomme dem. Du kan fx italesætte prisen ved at påpege fejlinvesteringer eller frås, som man
            kunne ha’ brugt samme beløb på. Giv gerne en grund til, hvorfor de skal handle netop nu. Måske gælder dit tilbud kun i en begrænset periode? Måske er der kun et begrænset
            antal til salg? Der er mange måder at tale til folks frygt for at miste noget attraktivt.
         </p>
         <p>
            Men altså. Det vigtigste er at fortælle folk, hvad du gerne vil have dem til, og at gøre det flere gange. En kort landingsside har måske 2 CTA-knapper, mens en længere
            sagtens kan have 3-5.
         </p>
         <BlogPostSubheader text="8. Øg din troværdighed" />
         <p>
            Medmindre du har en kendskabsgrad på 100 procent, så får du besøgende, som aldrig har hørt om dig før eller set din hjemmeside. De har måske bare klikket på en annonce og
            skal derfor overbevises om, at de trygt kan interagere med dig. Det kan du hjælpe på vej med udvalgte cases, anmeldelser fra Trustpilot eller andet, som øger din
            troværdighed.
         </p>
         <BlogPostSubheader text="9. Tjek din loadtid" />
         <p>
            Internetbrugere er utålmodige typer, og derfor er det vigtigt, at din landingsside loader hurtigt. Selv et enkelt sekund kan afgøre, om de bliver hængende eller smutter
            videre til konkurrenten.
         </p>
         <BlogPostSubheader text="10. Sæt mål for din succes" />
         <p>
            Hvordan ved du om din nye landingsside er en succes? Tjek din konverteringsrate i Analytics. Se også hvor mange der bouncer, og hvor længe folk bliver på siden. Med de tre
            målepunkter får du en god fornemmelse for værdien af din landingsside.
         </p>
         <p>Det var dagens tips – håber de kan bruges.</p>
         <p>Kh. Kirstine</p>
      </BlogPostContainer>
   );
};

export default TopkonverterendeLandingsside;

import React from "react";
import { Link } from "react-router-dom";

const ErrorNotFound = () => {
	return (
		<div className="container">
			<h1 className="is-family-secondary four-o-four-error has-text-green-dark has-text-centered">404</h1>
			<p className="has-text-centered four-o-four-text">
				Hov! Du søgte på en side, der ikke eksisterer (og derfor landede du her).
			</p>
			<p className="has-text-centered">
				<Link to="/" className="button is-primary four-o-four-button">
					Tilbage til forsiden
				</Link>
			</p>
		</div>
	);
};

export default ErrorNotFound;

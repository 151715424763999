import React from "react";
import { Link } from "react-router-dom";

const PricingContainer = () => {
   return (
      <React.Fragment>
         <section className="section pricing-banner">
            <div className="container">
               <h1 className="title is-1 has-text-white">Tekster, der sælger koster kroner</h1>
               <h2 className="subtitle has-text-white">Til gengæld er pengene hurtigt tjent ind igen</h2>
            </div>
         </section>
         <section className="section content-section">
            <div className="container">
               <h2 className="title has-text-centered">Klar til at trænge gennem lydmuren?</h2>
               <div className="is-divider-thick block"></div>
               <p className="block">Du har et budskab, du gerne vil ud med. Jeg har evnerne til at optimere det. Måske er det nu, du skal have hjælp fra en specialist?</p>
               <p className="block">Ja, det koster penge at hyre mig, men du betaler jo også for hjælp hos mekanikeren, når din bil ikke virker. Og hvis jeg tilbød arbejdet gratis, var jeg nok ikke specielt dygtig.</p>
               <p className="block">Jeg kan hjælpe med både små og store projekter. Har du brug for en fast skribent til fx nyhedsbreve, blog eller SoMe, kan du vælge mellem forskellige pakkeløsninger nedenfor.</p>
               <p className="block">Har du kun brug for hjælp til en enkeltstående opgave, laver jeg et skræddersyet tilbud til dig. Her ligger timeprisen på 1.095 DKK ekskl. moms.</p>
               <p className="block"><strong>Glem ikke...</strong></p>
               <p className="block">Hos mig slipper du for alt bøvlet med pension, ekstra kontorplads, feriepenge og ATP. Jeg arbejder på mit eget kontor, og du betaler kun for effektive timer.</p>
            </div>
         </section>
         <section className="section content-section">
            <div className="container">
               <h2 className="title has-text-centered">Spar op til 25 % med en fast aftale*</h2>
               <div className="is-divider-thick block"></div>
               <div className="tile is-ancestor">
                  <div className="tile is-parent">
                     <div className="tile is-child">
                        <div className="card">
                           <header className="card-header has-background-green">
                              <p className="card-header-title is-uppercase has-text-white is-centered">
                                 1 dag pr. måned
                              </p>
                           </header>
                           <div className="card-content content">
                              <div className="columns">
                                 <div className="column is-10 is-offset-2">
                                    <ul>
                                       <li>5 timer pr. dag</li>
                                       <li>3 måneders binding</li>
                                       <li>1 måneds opsigelse</li>
                                    </ul>
                                 </div>
                              </div>
                              <div className="content has-text-centered">
                                 <p className="title price-tag">4.995</p>
                                 <p>pr. dag ekskl. moms</p>
                                 <p className="is-italic">Timepris 999 DKK</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="tile is-parent">
                     <div className="tile is-child">
                        <div className="card">
                           <header className="card-header price-has-background-darker-green">
                              <p className="card-header-title is-uppercase has-text-white is-centered">
                                 2 dage pr. måned
                              </p>
                           </header>
                           <div className="card-content content">
                              <div className="columns">
                                 <div className="column is-10 is-offset-2">
                                    <ul>
                                       <li>5 timer pr. dag</li>
                                       <li>2 måneders binding</li>
                                       <li>1 måneds opsigelse</li>
                                    </ul>
                                 </div>
                              </div>
                              <div className="content has-text-centered">
                                 <p className="title price-tag">4.495</p>
                                 <p>pr. dag ekskl. moms</p>
                                 <p className="is-italic">Timepris 899 DKK</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div><div className="tile is-parent">
                     <div className="tile is-child">
                        <div className="card">
                           <header className="card-header price-has-background-lighter-green">
                              <p className="card-header-title is-uppercase has-text-white is-centered">
                                 4 dage pr. måned
                              </p>
                           </header>
                           <div className="card-content content">
                              <div className="columns">
                                 <div className="column is-10 is-offset-2">
                                    <ul>
                                       <li>5 timer pr. dag</li>
                                       <li>1 måneds binding</li>
                                       <li>1 måneds opsigelse</li>
                                    </ul>
                                 </div>
                              </div>
                              <div className="content has-text-centered">
                                 <p className="title price-tag">3.995</p>
                                 <p>pr. dag ekskl. moms</p>
                                 <p className="is-italic">Timepris 799 DKK</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <p className="has-text-centered is-italic">* Sammenlignet med timeprisen på 1.095 DKK for enkeltstående opgaver.</p>
            </div>
         </section>
         <section className="section content-section">
            <div className="container">
               <h2 className="title has-text-centered">Det kan jeg nå på en dag</h2>
               <div className="is-divider-thick is-block"></div>
               <div className="content">
                  <p>
                     Nysgerrig? Det er fair nok.
                  </p>
                  <p>Hvor meget jeg når på en dag, handler jo i sidste ende om, hvor meget du får for pengene.
                     Derfor har jeg lavet en lille oversigt, hvor du kan se, hvad jeg plejer at kunne nå på en helt almindelig dag a 5 timers effektivt arbejde:</p>
                  <ul>
                     <li>Skrive 2-3 e-mails</li>
                     <li>Skitsere en længere e-mail kampagne og skrive den første mail</li>
                     <li>Optimere og redigere teksten i 4-5 eksisterende mails</li>
                     <li>Lave contentplan for et kvartal</li>
                     <li>Skrive 7-10 opslag til Facebook, LinkedIn eller Instagram</li>
                     <li>Skrive 1-2 blogindlæg</li>
                     <li>Optimere og redigere teksten i 3-4 eksisterende blogindlæg</li>
                     <li>Skitsere en landingsside og skrive cirka halvdelen af teksten</li>
                  </ul>
                  <h3>Det med småt</h3>
                  <p>Listen her er kun eksempler på, hvad jeg tidligere har nået – ikke et løfte om, at jeg kan nå præcis det samme for dig.</p>
                  <p>
                     Generelt gælder det, at jo længere vi har arbejdet sammen, og jo bedre jeg kender din virksomhed og din målgruppe,
                     jo hurtigere går det. Når det er sagt, så vil det endelige tidsforbrug altid afhænge af,
                     hvor godt opgaven er beskrevet, hvor meget jeg skal researche og hvor lang den e-mail fx skal være.
                  </p>
                  <Link to={"kontakt"} className="button is-primary">Få tilbud nu</Link>
               </div>
            </div>
         </section>
      </React.Fragment>
   );
};

export default PricingContainer;

import React from "react";
import { Link } from "react-router-dom";

const BlogCard = ({ link, title, text, date, imgurl }) => {
   return (
      <div className="tile is-parent">
         <div className="tile is-child">
            <div className="card">
               <div className="card-image">
                  <Link to={"blog/" + link}>
                     <figure className="image is-4by3">
                        <img src={imgurl} alt="test" />
                     </figure>
                  </Link>
               </div>
               <div className="card-content">
                  <div className="content">
                     <h1 className="title is-4">{title}</h1>
                     <p className="has-text-grey is-italic">{date}</p>
                     <p>{text}</p>
                     <Link to={"blog/" + link} className="button is-primary">
                        Læs indlægget
                     </Link>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default BlogCard;

import React from "react";

const BlogTextBox = (props) => {
  return (
    <div className="box">
      <p>{props.children}</p>
    </div>
  );
};

export default BlogTextBox;

import React from "react";
import { Helmet } from "react-helmet";
import AboutMeContainer from "../../Components/AboutMeContainer";
import ContactContainer from "../Contact/ContactContainer";
import CompetencyContainer from "./CompetencyContainer";

const CompetencyPage = () => {
	return (
		<React.Fragment>
			<Helmet>
				<title>Kompetencer</title>
			</Helmet>
			<CompetencyContainer />
			<AboutMeContainer />
			<ContactContainer />
		</React.Fragment>
	);
};

export default CompetencyPage;

import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollMemory from "react-router-scroll-memory";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./_styles/bulma.scss";
import "./font-awesome";
import Menu from "./Menu/Menu";
import Frontpage from "./Pages/Frontpage/Frontpage";
import PricingPage from "./Pages/Pricing/PricingPage";
import CompetencyPage from "./Pages/Competences/CompetencyPage";
import ReferencePage from "./Pages/References/ReferencePage";
import ContactPage from "./Pages/Contact/ContactPage";
import Footer from "./Footer/Footer";
import ErrorNotFound from "./Pages/ErrorNotFound";
import BlogListPage from "./Pages/Blog/BlogListPage";
import CompetencySubPage from "./Pages/Competences/CompetencySubPage";
import MoreSubscriptionsNewsletter from "./Pages/Blog/Posts/MoreSubscriptionsNewsletter";
import TopkonverterendeLandingsside from "./Pages/Blog/Posts/TopkonverterendeLandingsside";
import CookiesAndPersonalData from "./Pages/CookiesAndPersonalData";
import DineKunderVilElskeDenHerOverskrift from "./Pages/Blog/Posts/DineKunderVilElskeDenHerOverskrift";
import SeksAlternativeBlackFridayKampagner from "./Pages/Blog/Posts/SeksAlternativeBlackFridayKampagner";
import TreAutomatiseredeMails from "./Pages/Blog/Posts/TreAutomatiseredeMails";
import NavlePrincippet from "./Pages/Blog/Posts/NavlePrincippet";
import CustomerFlowEmailPage from "./Pages/CustomerFlowEmail/CustomerFlowEmailPage";
import Tekstmuren from "./Pages/Blog/Posts/Tekstmuren";
import TermsAndConditions from "./Pages/TermsAndConditions";

toast.configure({
   autoClose: 2000,
   hideProgressBar: true,
   position: "bottom-left",
});

function App() {
   return (
      <React.Fragment>
         <Router>
            <ScrollMemory />
            <Menu />
            <Switch>
               <Route path="/" exact component={Frontpage} />
               <Route path="/kompetencer" component={CompetencyPage} />
               <Route path="/referencer" component={ReferencePage} />
               <Route path="/gratis-guldkorn" component={BlogListPage} />
               <Route path="/priser" component={PricingPage} />
               <Route path="/kontakt" component={ContactPage} />
               <Route path="/kompetence/:link" component={CompetencySubPage} />
               <Route path="/blog/faa-flere-tilmeldinger-til-dit-nyhedsbrev-nu" component={MoreSubscriptionsNewsletter} />
               <Route path="/blog/saadan-laver-du-en-topkonverterende-landingsside" component={TopkonverterendeLandingsside} />
               <Route path="/blog/dine-kunder-vil-elske-den-her-overskrift" component={DineKunderVilElskeDenHerOverskrift} />
               <Route path="/blog/seks-alternative-black-friday-kampagner" component={SeksAlternativeBlackFridayKampagner} />
               <Route path="/blog/tre-automatiserede-mails" component={TreAutomatiseredeMails} />
               <Route path="/blog/navle-princippet" component={NavlePrincippet} />
               <Route path="/blog/tekstmuren" component={Tekstmuren} />
               <Route path="/cookies" component={CookiesAndPersonalData} />
               <Route path="/tilbud" component={CustomerFlowEmailPage} />
               <Route path="/handelsbetingelser" component={TermsAndConditions} />
               <Route path="*" component={ErrorNotFound} />
            </Switch>
            <Footer />
         </Router>
      </React.Fragment>
   );
}

export default App;

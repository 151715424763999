import React from "react";
import "./frontpage.scss";
import HeroContainer from "../../Components/HeroContainer";
import AboutMeContainer from "../../Components/AboutMeContainer";
import Carousel, { CarouselItem } from "../../Components/Carousel/Carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faPenToSquare, faCalendar, faComments } from "@fortawesome/free-regular-svg-icons";


const Frontpage = () => {
   const img = require("../../_images/logoer_1200x200.png");
   const img2 = require("../../_images/logoer_vol_II_1200x200.png");
   const img_mobile = require("../../_images/logoer_mobil_1100x2200.png");
   const img_mobile2 = require("../../_images/logoer_mobil_vol II_1100x2200.png");
   return (
      <React.Fragment>
         <HeroContainer />
         <section className="section content-section">
            <div className="container">
               <h2 className="title has-text-centered">
                  Udvalgte kunder
               </h2>
               <div className="is-divider-thick block"></div>
               <figure className="image is-hidden-mobile">
                  <img src={img} alt="Kunder"></img>
               </figure>
               <figure className="image is-hidden-mobile">
                  <img src={img2} alt="Kunder"></img>
               </figure>
               <figure className="image is-hidden-tablet">
                  <img src={img_mobile} alt="Kunder"></img>
               </figure>
               <figure className="image is-hidden-tablet">
                  <img src={img_mobile2} alt="Kunder"></img>
               </figure>
            </div>
         </section>
         <section className="section content-section">
            <div className="container">
               <h2 className="title has-text-centered">
                  Det kan du få hjælp til
               </h2>
               <div className="is-divider-thick block"></div>
               <div className="tile is-ancestor">
                  <div className="tile is-parent">
                     <div className="tile card is-child">
                        <div className="card-content">
                           <div className="content">
                              <p className="has-text-centered"><FontAwesomeIcon icon={faPenToSquare} size="4x" /></p>
                              <h4 className="has-text-centered">Tekstforfatning</h4>
                              <p>Jeg elsker at skrive, og jeg kan noget med ord. Med min hjælp får du lækre tekster til e-mails, sociale medier, blogindlæg, landingssider mm. Alt sammen skrevet i et levende og letlæseligt sprog uden stavefejl.</p>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="tile is-parent">
                     <div className="tile card is-child">
                        <div className="card-content">
                           <div className="content">
                              <p className="has-text-centered"><FontAwesomeIcon icon={faCalendar} size="4x" /></p>
                              <h4 className="has-text-centered">Årshjul og contentplan</h4>
                              <p>Det er bare lidt nemmere at lave godt indhold, når du har styr på din kalender. Jeg hjælper med alt fra brainstorm på årshjul til konkrete contentplaner og sikrer de bedste betingelser for indhold, der skaber engagement og omsætning. </p>
                           </div>
                        </div>
                     </div>
                  </div><div className="tile is-parent">
                     <div className="tile card is-child">
                        <div className="card-content">
                           <div className="content">
                              <p className="has-text-centered"><FontAwesomeIcon icon={faComments} size="4x" /></p>
                              <h4 className="has-text-centered">Strategisk sparring</h4>
                              <p>For at få mest muligt ud af din markedsføring og dine tekster skal du først og fremmest have styr på basen: Markedet, konkurrenterne, målgruppen og så videre. Med min hjælp går du fra ad hoc markedsføring til strategiske valg. </p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section className="section has-frontpage-green-background content-section">
            <div className="container">
               <h2 className="title has-text-centered">
                  Tre gode grunde til at hyre mig
               </h2>
               <div className="is-divider-thick block"></div>
               <div className="list">
                  <div>
                     <strong className="is-uppercase is-size-5">Ærlig feedback</strong>
                     <p className="list-text">Jeg kan ikke hjælpe dig med at skabe resultater, hvis jeg ikke må komme med konstruktiv kritik. Derfor siger jeg, hvad jeg synes, så du har det bedste grundlag for at træffe en beslutning.</p>
                     <br />
                  </div>
                  <div>
                     <strong className="is-uppercase is-size-5">Effektivitet</strong>
                     <p className="list-text">Når jeg arbejder, så arbejder jeg. Min telefon ligger i et andet rum, jeg har lukket ned for mailen, og jeg fokuserer 100 procent på den opgave, der skal løses. På den måde får du mest for pengene, og jeg får en god arbejdsdag.</p>
                     <br />
                  </div>
                  <div>
                     <strong className="is-uppercase is-size-5">Dedikation</strong>
                     <p className="list-text">Jeg hygger mig virkelig, når jeg sidder og skriver. Hos mig får du en oprigtigt passioneret tekstforfatter, der elsker at lege med ord og skabe spændende tekster.</p>
                     <br />
                  </div>
               </div>
               <div className="has-text-centered">
                  <Link to="/kontakt" className="button is-medium call-to-action has-background-green-dark has-text-white">
                     Sæt i gang
                  </Link>
               </div>
            </div>
         </section>
         <section className="section content-section">
            <div className="container">
               <h2 className="title has-text-centered">
                  Sådan foregår samarbejdet
               </h2>
               <div className="is-divider-thick block"></div>
               <div className="content block">
                  <p>De fleste af mine kunder vælger et fast samarbejde, men jeg løser også enkeltopgaver som fx:</p>
                  <ul>
                     <li>Optimering af dine eksisterende webtekster</li>
                     <li>En ny landingsside</li>
                     <li>En ny velkomstserie til dit nyhedsbrev</li>
                  </ul>
                  <p><strong>Uanset hvor lille eller stor opgaven er, starter vi altid med en uformel snak,</strong> hvor vi lærer hinanden
                     bedre at kende og ser, om vi er et godt match. Jeg spørger ind til dig og din virksomhed – du fortæller om
                     dine ønsker og behov.</p>
                  <p>
                     Det er umuligt at være god til det hele, og derfor siger jeg fra, hvis du ønsker min hjælp til områder, der
                     rækker ud over min ekspertise. Det gælder opgaver som:
                  </p>
                  <ul>
                     <li>Opsætning af annoncer</li>
                     <li>Håndtering af sociale medier</li>
                     <li>Webredigering</li>
                     <li>Søgeordsanalyse</li>
                     <li>Opsætning og udsendelse af e-mails</li>
                  </ul>
                  <p><strong>Er der ok fra begge sider, sender jeg dig et konkret tilbud via mail.</strong> Så snart du har takket ja, booker
                     jeg tid i min kalender. Nogle gange har jeg 2-4 ugers ventetid, andre gange har jeg hul i kalenderen før.</p>
                  <h3>OPSTART, KORREKTUR OG OPTIMERING</h3>
                  <p>
                     Jeg har brug for en del detaljer, før jeg kan gå i gang. Derfor starter vi med en grundig snak, hvor jeg bliver
                     godt klædt på i forhold til målgruppe, produkter, tone of voice og så videre.
                  </p>
                  <p><strong>Derefter begynder jeg at skrible.</strong></p>
                  <p>
                     Når jeg er færdig, sender jeg dig et udkast, og du melder retur med eventuelle rettelser og kommentarer.
                     Jeg retter til og bum, så står du med en færdig tekst, som du kan poste på Facebook eller hvor den nu
                     skal bruges.
                  </p>
                  <p>
                     Har vi et længere samarbejde, hjælper jeg gerne med at optimere, så vi bliver endnu skarpere næste
                     gang. Du indsamler data og evaluerer – jeg laver nye tekster, som vi kan teste af.
                  </p>
               </div>
            </div>
         </section>
         <section className="section content-section">
            <div className="container">
               <h2 className="title has-text-centered">
                  Gode tekster skaber værdi
               </h2>
               <div className="is-divider-thick block"></div>
               <div className="content block">
                  <p>Dine kunder har travlt med alt muligt. De skal hente børn, tjekke mails og scrolle på Instagram – hvorfor skulle de bruge tid på at læse dine tekster?</p>
                  <p>De læser dem kun, hvis du har noget spændende at byde ind med. Og de foretager kun en handling, hvis de føler, at den handling skaber reel værdi.</p>
                  <p>Uanset hvilken reaktion du ønsker fra dine læsere, så er ord et kraftfuldt virkemiddel. Den helt rigtige kombination af bogstaver kan få folk til at åbne dine mails, klikke på dine annoncer, læse dine blogindlæg, downloade din e-bog eller lægge noget i kurven.</p>
                  <p><strong>Men det kræver en skribent, der ved noget om tekster.</strong></p>
                  <p>En, der har styr på alt fra det danske sprog til salgspsykologi, triggers og skrivemodeller, og det har jeg. Jeg har optimeret mine skriveevner gennem de sidste 10 år, og jeg er blevet ganske god.</p>
                  <p>Det bilder jeg i hvert fald mig selv ind, heldigvis er mine kunder enige.</p>
                  <br />
                  <br />
                  <p className="has-text-centered">
                     <Link to="/kontakt" className="button is-medium call-to-action has-text-centered has-background-green-dark has-text-white">
                        Kom med i klubben
                     </Link>
                  </p>
               </div>
            </div>
         </section>
         <section className="section content-section">
            <div className="container">
               <h2 className="title has-text-centered">
                  Få syn for sagen
               </h2>
               <div className="is-divider-thick block"></div>
               <div className="content block">
                  <div className="columns">
                     <div className="column is-6 is-offset-3">
                        <div className="card carousel-card">
                           <div className="card-content has-text-centered">
                              <Carousel>
                                 <CarouselItem>
                                    <div className="carousel-inner">
                                       <h3>UNØDVENDIGE BEKYMRINGER</h3>
                                       <p className="is-italic">”Inden starten af vores samarbejde, var jeg i kontakt med flere tekstforfattere, og jeg var da også stærkt i tvivl om jeg nu valgte den rigtige.
                                          Men de bekymringer skulle vise sig at være spildte, da jeg modtog den første trænerprofil – det var tydeligt at Kirstine havde forstået målgruppen og tonen vi ønskede.</p>
                                       <p><strong>Kasper V. Christensen</strong></p>
                                       <p>Fortius Fitness</p>
                                    </div>
                                 </CarouselItem>
                                 <CarouselItem>
                                    <div className="carousel-inner">
                                       <h3>GOD INVESTERING</h3>
                                       <p className="is-italic">“Med Kirstines hjælp kom jeg godt i gang med leadgenerering og e-mail marketing, herunder en velkomstserie til mit nyhedsbrev. Mine udgifter til konsulentydelser er for længst tjent hjem, efter jeg implementerede hendes idéer
                                          – stor anbefaling herfra!”</p>
                                       <p><strong>Jens Holm-Christensen</strong></p>
                                       <p>Bolcheværk</p>
                                    </div>
                                 </CarouselItem>
                                 <CarouselItem>
                                    <div className="carousel-inner">
                                       <h3>LYTTENDE SPARRINGSPARTNER</h3>
                                       <p className="is-italic">”Jeg er ikke selv super skarp på det med markedsføring, og derfor besluttede jeg mig for at få hjælp udefra. Kirstine og jeg har snakket om alt fra blog til nyhedsbrev og SEO,
                                          og hver eneste gang bliver jeg mødt af en kompetent og lyttende sparringspartner. Hun lyder aldrig bedrevidende – det er dejligt.
                                       </p>
                                       <p><strong>Leila Boel</strong></p>
                                       <p>Proceskonsulent og mediator</p>
                                    </div>
                                 </CarouselItem>
                                 <CarouselItem>
                                    <div className="carousel-inner">
                                       <h3>FÆRRE MAILS – FLERE PENGE</h3>
                                       <p className="is-italic">”Kirstine testede en ny contentstrategi til vores e-mails. Færre udsendte mails pr. måned, skarpere indhold og en omsætning på 23 % over target – yay!”</p>
                                       <p><strong>Stefan Primdahl</strong></p>
                                       <p>WhiteAway</p>
                                    </div>
                                 </CarouselItem>
                                 <CarouselItem>
                                    <div className="carousel-inner">
                                       <h3>MEGET EFFEKTIV</h3>
                                       <p className="is-italic">”Inden samarbejdet startede, var det vigtigt at sikre mig, at Kirstine havde de nødvendige kompetencer og den nødvendige tid,
                                          da jeg var lidt sent på den. Jeg blev hurtigt overbevist om, at Kirstine havde begge dele. Kompetencerne fik hun hurtigt bevist og tidsdelen løser hun ved at være meget effektiv.</p>
                                       <p><strong>Per Nørbo</strong></p>
                                       <p>Sansestærk.dk</p>
                                    </div>
                                 </CarouselItem>
                              </Carousel>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <AboutMeContainer />
         <section className="section has-frontpage-green-background content-section">
            <div className="container">
               <h2 className="title has-text-centered">
                  Skal dine tekster sælge?
               </h2>
               <div className="is-divider-thick block"></div>
               <div className="content block has-text-centered">
                  <p>Bare giv lyd, så sender jeg et stærkt tilbud i din retning. </p>
                  <br />
                  <br />
                  <br />
                  <Link to="/kontakt" className="button frontpage-dark has-text-white is-medium call-to-action">
                     Få tilbud nu
                  </Link>
               </div>
            </div>
         </section>
      </React.Fragment >
   );
};

export default Frontpage;

import React from "react";
import { Helmet } from "react-helmet";
import BlogPostContainer from "./Blog/BlogPostContainer";

const TermsAndConditions = () => {
   return (
      <BlogPostContainer>
         <Helmet>
            <title>Handelsbetingelser</title>
         </Helmet>
         <h1 className="title">Handelsbetingelser</h1>
         <p>Marketing med mere</p>
         <p>8600 Skanderborg</p>
         <p>CVR: 40687513</p>
         <h5 className="subtitle is-uppercase blog-subtitle">1. Tilbud og aftale</h5>
         <p>Alle tilbud gælder i 7 dage, medmindre andet er aftalt.</p>
         <p>Aftalen er indgået, så snart jeg modtager din accept via mail.</p>
         <h5 className="subtitle is-uppercase blog-subtitle">2. Priser</h5>
         <p>Alle priser er eksklusiv moms.</p>
         <p>Ved faste aftaler får du lavere timepris og stabile omkostninger.</p>
         <p>Ved enkeltopgaver sender jeg tidsestimat og pris ud fra de oplysninger, du har givet. Hvis opgavens omfang eller specifikationer ændrer sig mærkbart, fakturerer jeg for det merarbejde, ændringen medfører.</p>
         <h5 className="subtitle is-uppercase blog-subtitle">3. Betaling</h5>
         <p>Ved faste aftaler sendes en faktura, når månedens arbejdsdag(e) er afsluttet.</p>
         <p>Ved mindre enkeltopgaver betaler du, når opgaven er løst. Ved større enkeltopgaver, der strækker sig over længere tid, får du en samlet faktura i slutningen af hver måned.</p>
         <p>Du kan ikke fortryde dit køb ved at undlade at betale, og du får ikke betalte beløb refunderet, hvis du springer fra midt i processen. Betaler du ikke til tiden, har jeg ret til at aflyse eller udskyde efterfølgende aftaler, indtil fakturaen er betalt.</p>
         <p>Betalingsbetingelser: Netto 14 dage. Ved betaling efter forfald tilskrives der renter på 0,70% pr. påbegyndt måned samt et gebyr på 100 DKK.</p>
         <h5 className="subtitle is-uppercase blog-subtitle">4. Overholdelse af aftaler og deadlines</h5>
         <p>Jeg gør mit ypperste for at overholde alle aftaler og deadlines, og jeg forventer, at du gør det samme. På den måde får vi begge mest muligt ud af samarbejdet og undgår spildtid og frustration.</p>
         <p>Uanset om vi har indgået et samarbejde om en fast aftale eller en enkeltopgave, har jeg blokeret plads i min kalender til dig og din virksomhed.</p>
         <p><strong>Ved enkeltopgaver </strong> briefer du mig i starten og/eller løbende, og vi aftaler en deadline for opgaven. Overholder du ikke vores aftaler undervejs, er min planlægning spildt, og jeg kan ikke længere garantere en overholdelse af den oprindelige deadline.</p>
         <p>Sker det gentagne gange, at du bryder vores aftale, sender jeg en faktura for spildt arbejdstid.</p>
         <p><strong>Ved faste aftaler</strong> booker vi altid en måned frem i tiden, så du ved præcis, hvilke dage jeg står til din rådighed.</p>
         <p>Vi taler løbende sammen omkring de ting, der skal laves, og du giver brief på kommende opgaver senest to hverdage før den aftalte arbejdsdag. </p>
         <p>Aflyser du dagen, betaler du stadig for den. Sker det gentagne gange, har jeg ret til at opsige vores samarbejde.</p>
         <h5 className="subtitle is-uppercase blog-subtitle">5. Brugs- og ophavsret </h5>
         <p>Du får ophavs- og brugsretten til tekster mm. i det øjeblik, jeg modtager betaling for mit arbejde.</p>
         <p>Betaler du ikke til tiden, opnår du ikke brugs- og ophavsretten til teksterne. Bruger du teksterne uden at have opnået brugs- og ophavsret, anser jeg det som et brud på ophavsretten og kan kræve erstatning.</p>
         <h5 className="subtitle is-uppercase blog-subtitle">6. Ferie</h5>
         <p>Jeg holder som udgangspunkt ferie hele juli samt i højtider og skoleferier. Har du en fast aftale, informerer jeg om eventuel ferie i så god tid som muligt og sørger for at lægge dine dage uden for min ferie. Du kan altså forvente at få en eller flere faste arbejdsdage i alle årets måneder, undtagen juli.</p>
         <h5 className="subtitle is-uppercase blog-subtitle">7. Force majeure</h5>
         <p>Du kan ikke holde mig ansvarlig for forsinkelse eller mangler, som skyldes forhold uden for min indflydelse. Det gælder situationer som brand, vandskade, beskadigelse af arbejdsudstyr, tvingende familiemæssige eller personlige årsager såsom alvorlig sygdom eller død, naturkatastrofer, epidemier, krig eller lignende.</p>
         <p>Bliver en opgave forsinket på grund af ovenstående, aftaler vi en ny leveringsdato snarest muligt.</p>
      </BlogPostContainer>
   );
};

export default TermsAndConditions;

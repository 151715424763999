import React from 'react'
import CustomerFlowHeroContainer from "./CustomerFlowHeroContainer";
import CustomerFlowEmailContainer from './CustomerFlowEmailContainer';
import CustomerFlowContactContainer from './CustomerFlowContactContainer';
import CustomerFlowReferenceContainer from './CustomerFlowReferenceContainer';

const CustomerFlowEmailPage = () => {
   return (
      <React.Fragment>
         <CustomerFlowHeroContainer />
         <CustomerFlowEmailContainer />
         <CustomerFlowReferenceContainer />
         <CustomerFlowContactContainer />
      </React.Fragment>
   );
}

export default CustomerFlowEmailPage;
import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import BlogPostContainer from "../BlogPostContainer";
import BlogPostDateAndReadtime from "../BlogPostDateAndReadtime";
import BlogPostSubheader from "../BlogPostSubheader";

const SeksAlternativeBlackFridayKampagner = () => {
   const blackFriday1 = require("../../../_images/6-alternative-black-friday-kampanger/black-friday-1.jpg");
   const cardsAgainstHumanity = require("../../../_images/6-alternative-black-friday-kampanger/cards-against-humanity.jpg");
   const jigsaw = require("../../../_images/6-alternative-black-friday-kampanger/jigsaw-black-friday.png");
   const optOutside = require("../../../_images/6-alternative-black-friday-kampanger/opt-outside.png");
   return (
      <BlogPostContainer>
         <Helmet>
            <title>6 alternative Black Friday kampagner (uden tilbud!)</title>
         </Helmet>
         <h1 className="title">6 alternative Black Friday kampagner (uden tilbud!)</h1>
         <BlogPostDateAndReadtime date="2. oktober 2020" readtime="Læsetid: 5-6 min" />
         <figure className="image">
            <img src={blackFriday1} alt="black-friday-1.jpg" />
         </figure>
         <p>
            Årets største handelsdag nærmer sig med hastige skridt, og mange virksomheder er allerede godt i gang med at skaffe varer, der kan sælges med stor rabat. Men hvad med alle
            dem, der ikke ønsker at være en del af forbrugsfesten? De virksomheder, som ikke finder priskrig og tilbudsfest attraktivt, men som alligevel føler sig nødsaget til at gøre
            et eller andet, fordi ALLE taler om Black Friday.
         </p>
         <p>
            Heldigvis findes der alternativer, og det har jeg fundet et par eksempler på. Nogle handler om salg, nogle om awareness, men fælles for alle er, at de er optimale for
            virksomheder, der ikke vil give rabat på Black Friday.
         </p>
         <BlogPostSubheader text="1. Underhold folket med en chokerende quiz" />
         <p>
            Hvis du ikke vil tale om tilbud, må du dreje snakken hen på noget andet interessant. Du kan fx lave en quiz, hvor folk skal gætte, hvor stor betydning Black Friday har for
            miljøet. Det kunne også være spørgsmål omkring mængden af tid spildt på at stå i kø eller lignende. Der findes en del undersøgelser derude, så det er bare med at google
            løs.
         </p>
         <p>
            Med en quiz tilbyder du kunderne gratis underholdning, hvilket kan skabe gode resultater selv med et begrænset budget. Her øger du nemlig chancen for at folk deler dit
            indhold helt af sig selv, fordi de ganske enkelt syntes det var sjovt eller interessant.
         </p>
         <p>
            Der findes flere store (og dyre) udbydere med fantastiske <Link to="/blog/gamification-derfor-virker-det">gamification</Link> platforme, men du kan også være lidt kreativ
            og køre en god omgang{" "}
            <a href="https://kahoot.com/" target="_blank" rel="noopener noreferrer">
               Kahoot
            </a>{" "}
            i en Facebook live session, hvis budgettet mere er til det.
         </p>
         <p>
            Husk at dine spørgsmål skal være spændende, gerne med chokerende eller sjove svarmuligheder. Hvis du laver en quiz med basisviden og kedelige spørgsmål, får du ikke meget
            opmærksomhed.
         </p>
         <BlogPostSubheader text="2. Luk butikken og giv dine ansatte en fridag" />
         <p>
            Du kan også overveje en lidt mere kontroversiel kampagne inspireret af den amerikanske butikskæde REI, som sælger udstyr til camping og friluftsliv. REI har i flere år haft
            stor succes med at give sine ansatte fri på Black Friday, og samtidig opfordre dem og alle andre til at bruge dagen ude i naturen. Virksomheden lukker alle sine butikker
            inklusiv webshoppen, og siger dermed farvel til en kæmpe omsætning.
         </p>
         <p>
            Til gengæld er de faktisk lykkes med at lave en hel bevægelse omkring deres #OptOutside kampagne, som nu efterhånden er blevet en tradition. De skiller sig i den grad ud
            fra konkurrenterne og tilføjer samtidig nogle positivt ladede værdier til deres brand.
         </p>
         <p>
            Har du en helt lille butik med kun dig selv og et par ansatte, får du nok ikke så meget opmærksomhed med et initiativ som dette. Du skal have omsætning og ansatte nok til,
            at det giver en wow-effekt, når du vinker farvel til alt Black Friday salg. Har du ikke nok volumen selv, kan du med fordel køre kampagnen sammen med andre. Måske den
            lokale handelsforening eller lignende.
         </p>
         <p>
            Og hey, hvis du sælger bøger, så kan du jo opfordre folk til at blive hjemme og læse en bog fremfor at tage på vandretur. Pointen her er selvfølgelig, at en optimal
            kampagne matcher dit produkt.
         </p>
         <figure className="image">
            <img src={optOutside} alt="opt-outside.png" />
         </figure>
         <BlogPostSubheader text="3. Sæt dine priser op og doner overskuddet til velgørenhed" />
         <p>
            Ja, det lyder måske lidt skørt, men du kan overveje at sætte dine priser op, og så donere overskuddet fra normalprisen til et velgørende formål. Her får du noget omsætning
            uden at gå på kompromis med din indtjening, og samtidig sætter du fokus på velgørenhed. Dine kampagner skal kredse om temaet ”Forkæl dig selv i den gode sags tjeneste”. Du
            skal altså ikke opfordre dine kunder til massivt forbrug, men derimod til at investere i noget, de virkelig ønsker sig, og som de kan købe på Black Friday med god
            samvittighed.
         </p>
         <BlogPostSubheader text="4. Sæt dine priser op og behold overskuddet selv" />
         <p>
            Tippet her er til dig, der ikke har noget imod at være den frække dreng i klassen. Her sætter du nemlig bare priserne op som et modsvar til alle rabatterne og laver lidt
            sjov med det.
         </p>
         <p>Se fx hvordan det amerikanske brætspil Cards Against Humanity (Det dårlige selskab) gjorde for et par år siden. Jeg er fan!</p>
         <figure className="image">
            <img src={cardsAgainstHumanity} alt="black-friday-2.jpg" />
         </figure>
         <BlogPostSubheader text="5. Byd på musik og gratis kaffe" />
         <p>
            Du kan også overveje at holde et lille event med gratis eller billig forplejning plus lidt musik. I København afholdes der efterhånden en del Black Friday arrangementer med
            street food, live musik og anden underholdning, som du kan koble dig på. Måske findes der også noget relevant i din by?
         </p>
         <p>
            Alternativt kan du selv stable noget på benene. Du kan fx leje en foodtruck med kaffe, som du får placeret foran din butik. Så sætter du et stort skilt op med noget a la:
         </p>
         <p className="is-offset-1">
            <span className="is-line-through">Black</span> Friendly Friday! Her er der gratis kaffe til alle, som er trætte af at shoppe amok og stå i kø. Vi har ingen tilbud – til
            gengæld er der kage til kaffen.
         </p>
         <BlogPostSubheader text="6. Fortæl dine kunder, at du ikke sænker priserne" />
         <p>Her skal du hverken hæve eller sænke dine priser, men simpelthen beholde dem som de er. Du skal heller ikke dele kaffe og kage ud eller tale om donationer.</p>
         <p>
            Det er der ikke meget fidus i, hvis du bare stopper der, men hvis du fortæller, hvorfor dine priser forbliver uændrede, kan du godt få noget opmærksomhed på det. Se fx
            hvordan den britiske tøjkæde Jigsaw gjorde i 2017:
         </p>
         <figure className="image">
            <img src={jigsaw} alt="jigsaw-black-friday.png" />
         </figure>
         <BlogPostSubheader text="Fordele ved en alternativ Black Friday" />
         <p>
            Vi danskere er et tilbudshungrede folk af kaliber, så ved at droppe rabatterne på Black Friday vinker du farvel til en stor del af din omsætning. Men alt efter størrelsen
            på din virksomhed, behøver det ikke at være så slemt endda. For mange små butikker er det svært at følge med på spotpriserne, som efterhånden nærmere varer en uge end en
            enkelt dag. Dækningsgraden er i bund, og tilbage står du med god omsætning, men ingen eller lille indtjening.
         </p>
         <p>En alternativ Black Friday kan skabe værdi på flere måder:</p>
         <ul>
            <li>
               Du får et oplagt emne til PR ved at skille dig ud og gøre noget anderledes. Jo større din virksomhed er, jo vildere er det, at du dropper tilbuddene. Er du en mindre
               forretning, kan du skabe mere opmærksomhed ved at få andre med på vognen.
            </li>
            <li>
               Vælger du at sætte fokus på miljø og mindre forbrug, skaber du en positiv stemning omkring dit brand. Det giver ikke øget salg nu og her, men tiltrækker en stor
               målgruppe, som skaber værdi på længere sigt.
            </li>
         </ul>
         <p>
            OBS. Inden du kaster dig ud i en grøn Black Friday kampagne, er det vigtigt at vurdere om budskabet passer til din virksomhed. At opbygge et grønt brand er en lang, sej
            kamp, og det kan nemt virke utroværdigt, hvis du pludselig skifter fra at være 100 procent kommerciel til nu at gå ind for mindre forbrug. Så mærk lige efter og se, hvad
            der passer til dig og din virksomhed.
         </p>
         <BlogPostSubheader text="Fortæl kunderne om din kampagne" />
         <p>
            Uanset hvilken vej du vælger, så får du ikke meget ud af din kampagne, medmindre du sørger for at markedsføre den. Der findes masser af tips til at optimere din Black
            Friday markedsføring. Har du et nyhedsbrev, skal du fx sørge for at få så mange på listen som muligt her i oktober, hvor priserne er billigere. På den måde kan du nemt (og
            gratis) minde kunderne om din kampagne, når tiden nærmer sig.
         </p>
         <p>
            Jeg får ikke plads til flere tips her, men google er din ven, og ellers er du velkommen til at sende mig en{" "}
            <a href="mailto:kirstine@marketingmedmere.dk" target="_blank" rel="noopener noreferrer">
               mail
            </a>
            , hvis du har spørgsmål.
         </p>
         <p>Jeg glæder mig til at se alle jeres spændende Black Friday kampagner – med tilbud eller ej :-)</p>
         <p>Kh. Kirstine</p>
      </BlogPostContainer>
   );
};

export default SeksAlternativeBlackFridayKampagner;

import React from 'react'
import Reference from "../References/Reference";
import references from "../../_data/_references.json";

const CustomerFlowReferenceContainer = () => {
   return (
      <section className="section">
         <div className="container">
            <div className="columns">
               <div className="column is-10 is-offset-1">

                  <div className="tile is-ancestor">
                     {references.map(reference => (
                        <Reference
                           key={reference.author}
                           reference={reference.text}
                           author={reference.author}
                           imgStr={reference.imgStr}
                           companyLink={reference.companyLink}
                           companyName={reference.companyName}
                        />
                     ))}
                  </div>

               </div>
            </div>
         </div>
      </section>
   )
}

export default CustomerFlowReferenceContainer

import React from "react";
import { Helmet } from "react-helmet";
import BlogPostContainer from "../BlogPostContainer";
import BlogPostDateAndReadtime from "../BlogPostDateAndReadtime";
import BlogPostSubheader from "../BlogPostSubheader";
import BlogTextBox from "../BlogTextBox";
import BlogLinkBox from "../BlogLinkBox";

const TreAutomatiseredeMails = () => {
   const welcome = require("../../../_images/tre-automatiserede-mails/welcome.jpg");
   const anniversary = require("../../../_images/tre-automatiserede-mails/anniversary.png");
   return (
      <BlogPostContainer>
         <Helmet>
            <title>3 automatiserede mails, som alle bør sende (ja, også dig)</title>
         </Helmet>
         <h1 className="title">3 automatiserede mails, som alle bør sende (ja, også dig)</h1>
         <BlogPostDateAndReadtime date="22. oktober 2022" readtime="Læsetid: 4-5 minutter" />
         <p>
            Automatiserede mails er en vidunderlig opfindelse, som desværre er overset af mange. Muligvis fordi det kræver tid at få sat op.
         </p>
         <p>
            Men faktisk er den smule tid det tager, en
            af de allerbedste investeringer, du kan gøre. Det smarte ved automatiseringer er jo netop, at du kun skal gøre arbejdet én gang. Derefter kan du læne dig tilbage og nyde
            effekten af din indsats.
         </p>
         <p>
            Hvis du er ny på det der med automatiske e-mails, så kommer her en liste med tre af de vigtigste (og nemmeste) kampagner, som jeg vil anbefale dig at starte ud med.
         </p>
         <p>
            Listen er i prioriteret rækkefølge, så husk at implementere tip nr. 1 som det første. De tre tips er i øvrigt relevante for alle – uanset om du har en webshop, en blog, sælger B2B
            produkter, konsulentydelser eller andet.
         </p>
         <BlogTextBox>
            Hvad er en automatiseret mail egentlig? Forklaringen kommer her: Et almindeligt nyhedsbrev udtænkes, opsættes og udsendes en enkelt gang, og forsvinder derefter. Det
            handler måske om produktnyheder, trends eller andre aktualiteter. En automatisk e-mail udtænkes og opsættes også kun en enkelt gang, men genbruges til gengæld mange gange,
            fordi den automatisk bliver sendt ud løbende baseret på en eller flere prædefinerede triggers.
         </BlogTextBox>
         <p>Og så til de tre automatiseringer, som jeg vil anbefale dig at sætte op.</p>
         <BlogPostSubheader text="1. Byd nye læsere velkommen" />
         <figure className="image">
            <img src={welcome} alt="velkommen" />
         </figure>
         <p>
            Du finder ikke en mail med højere åbningsrate end din allerførste mail, og det bør du selvfølgelig udnytte ved at opsætte en automatisk velkomstserie. Dvs. en række mails
            der automatisk udsendes, netop som et nyt lead har tilmeldt sig din liste.
         </p>
         <p>
            Du kan også nøjes med en enkelt mail, men hvis du vil øge din konvertering, bør du sende mindst
            tre mails ud, der handler om det samme – dog med forskellig vinkel.
         </p>

         <p>
            Og hvad skal sådan en velkomstserie så handle om?
         </p>
         <p>
            Det kommer selvfølgelig meget an på branche, målgruppe og så videre, men en gylden regel er, at det skal være generisk og
            uafhængigt af sæson. Din velkomstserie udsendes året rundt og skal helst ikke redigeres hver anden uge på grund af udgåede produkter eller andet.
         </p>
         <p>
            Har du en webshop, er salg formentlig et vigtigt parameter. Her kan du med fordel lave en velkomstserie, hvor du tilbyder en rabatkode, som fx udløber efter 7 dage. På den
            måde kan du opbygge en kampagneserie, hvor du først introducerer rabatten, og dernæst minder læseren om at bruge den, inden; koden udløber.
         </p>
         <p>
            Har du en blog eller et online forum, er du måske mere interesseret i at skabe trafik til din hjemmeside. Her kan du lave et velkomstflow, hvor du fremhæver forskellige
            kategorier, så nye læsere får øjnene op for alt det gode (gamle) indhold du har. Det kunne eksempelvis være ”De 5 mest populære indlæg på bloggen” eller lignende.
         </p>
         <p>
            Har du et mål om at få flere følgere på Instagram, LinkedIn eller andre sociale medier, kan du vælge at lave en velkomstserie, der fortæller om alle fordelene ved at følge
            dig der.
         </p>
         <p>
            Er du en B2B virksomhed med et kompliceret produkt, handler det måske mere om at uddanne læseren end at forcere et direkte salg. Her kan du lave en velkomstserie, hvor du
            introducerer udvalgte produkter og fortæller, hvordan de kan skabe værdi.
         </p>
         <BlogLinkBox link="/blog/faa-flere-tilmeldinger-til-dit-nyhedsbrev-nu" text="Se også hvordan du får flere modtagere til dit nyhedsbrev" isExternal={false} />
         <BlogPostSubheader text="2. Sig farvel til inaktive modtagere" />
         <p>
            Uanset hvor skønne, relevante og højt konverterende mails du sender ud, så vil der altid være nogen, der falder fra.
         </p>
         <p>
            De åbner sjældent eller aldrig dine mails, og gør du
            ikke noget ved det, kan du få problemer på sigt. Din åbningsrate vil falde, og du risikerer også at blive anmeldt som spam (selvom du har fået dine tilmeldinger på ærlig og
            redelig vis).
         </p>
         <p>
            Derfor bør du opsætte et automatisk flow, som hjælper dig med at genaktivere dine inaktive læsere eller fjerne dem fra listen. Triggeren på flowet kan eksempelvis være
            læsere, der ikke har åbnet i 6 måneder.
         </p>
         <p>
            Selve indholdet af din genaktiveringskampagne bør selvfølgelig have som mål at få dine læsere tilbage. Her kan du eksempelvis give dem tre gode grunde til at modtage dit
            nyhedsbrev, et tidsbegrænset tilbud, noget gratis content eller andet, som er relevant for netop din målgruppe.
         </p>
         <p>
            Hovedsagen er, at du motiverer dem til at blive på listen
            ved at komme med nogle overbevisende argumenter.
         </p>
         <p>
            Brug gerne blød, personlig kommunikation, der ikke anklager, men blot konstaterer. Du kan eksempelvis skrive ”Det ser ud, som om du ikke har åbnet de sidste mange mails,
            jeg har sendt”.
         </p>
         <p>
            På den måde imødekommer du også de få tilfælde, hvor modtageren rent faktisk har åbnet, men hvor der er gået noget galt med din tracking.
         </p>
         <p>
            Reagerer modtagerne ikke på din kampagne, bør de fjernes fra listen, eftersom de ikke længere har værdi. Det gælder ikke om at have den største liste, det gælder om at have
            den bedste.
         </p>
         <BlogLinkBox link="/kontakt" text="Få hjælp til din genaktiveringskampagne" isExternal={false} />
         <BlogPostSubheader text="3. Råb hurra på jeres årsdag" />
         <p>
            Nu hvor du har ryddet op i din liste, er det tid til at belønne dem, der stadig hænger på. Det kan du gøre ved at sende en hyggelig mail på jeres årsdag. Yes, den dag hvor
            du og din modtager mødtes i cyberspace for allerførste gang, da vedkommende tilmeldte sig din liste.
         </p>
         <p>
            En årsdags-mail er en fantastisk mulighed for at knytte et mere personligt bånd til dine modtagere. Du kan med fordel forkæle din læser lidt ved at forære en rabatkode, et
            pro-abonnement eller lignende som tak for året der gik.
         </p>
         <p>
            Alternativt kan du vælge at sende en fødselsdagshilsen, men eftersom de færreste har data på fødselsdage, er årsdagen en nemmere løsning.
         </p>
         <p>
            Vil du hellere gå med den
            klassiske fødselsdagshilsen, skal du selvfølgelig kende fødselsdagen for alle dine læsere. Det kan du eksempelvis få dem til at oplyse i din tilmeldingsformular, men husk
            at konverteringen falder, jo flere oplysninger der skal indtastes. Og hvis du nu skal vælge, er der måske andet data, som er mere relevant end netop fødselsdagen.
         </p>
         <figure className="image">
            <img src={anniversary} alt="hund" />
         </figure>
         <p>
            Der findes selvfølgelig også en masse andre automatiserede mails, som du med fordel kan opsætte. Har du fx en webshop, er der mange gode muligheder i forbindelse med
            købsadfærd. Måske skriver jeg et nyt indlæg om det en dag :-)
         </p>
         <p>
            Men dagens indlæg handler altså udelukkende om generiske automatiseringer, som alle uanset branche bør se på. Og husk: Du skaber altså ikke resultater bare ved at læse gode
            råd – de skal også implementeres :-)
         </p>
         <p>God fornøjelse og god dag derude.</p>
         <p>Kh. Kirstine</p>
      </BlogPostContainer>
   );
};

export default TreAutomatiseredeMails;
